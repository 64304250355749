import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import app from './appData';

// routes
import Home from './components/Home';
import KarinaFilms from './components/films/KarinaFilms';
import BetaLogin from './libs/BetaLogin';
import MindBody from './components/MindBody';
import About from './components/About';
import PhoenixDance from './components/films/PhoenixDance';
import Voices from './components/films/Voices';

export const menuItems = [
    { caption: "about", href: "/about" },
    { caption: "blog", href: app.blogUrl },
    { caption: "karinafilms", href: "/karina-films" },
    { caption: "mind/body", href: "/mind-body" },
    { caption: "projects", href: "/projects" },
];

export const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/beta-login" element={<BetaLogin />} />
                <Route path="/home" element={<Home />} />
                <Route path="/river" element={<Home />} />
                <Route path="/karina-films" element={<KarinaFilms />} />
                <Route path="/mind-body" element={<MindBody />} />
                <Route path="/about" element={<About />} />
                <Route path="/phoenix-dance" element={<PhoenixDance />} />
                <Route path="/voices" element={<Voices />} />
            </Routes>
        </BrowserRouter>
    );
}
export default AppRoutes;