import React from 'react';
import styled from 'styled-components';

import app from '../../appData';
import { formatImageUrl } from '../../libs/ImageFormatter';
import FilmPage, { AwardRecord, FilmDataRecord, FilmDataRecordType, ReviewRecord, ScreeningYearRecord } from './FilmPage';

const introText = `It seems impossible &mdash; but after losing a leg to cancer, accomplished dancer Homer Avila returns to the stage without crutches, performing with Andrea Flores 
a duet choreographed by Alonzo King. The rehearsal process is fierce and tender. Solo or intertwined, Homer collaborates in his now &quot;imperfect&quot; body &mdash; and 
his &quot;one-leggedness&quot; turns into transcendent beauty.`;

const reviews: ReviewRecord[] = [
    {
        text: `This extraordinary film by the German/American director draws a wonderful portrait of the late, great Homer Avila, an icon of American contemporary dance who lost a leg and hip to cancer. Part documentary, part performance we watch how the amazing Avila functions in the studio on one leg.`,
        author: "Paula Citron"
    },
    {
        text: `Karina Epperlein&apos;s deeply inspirational 16-minute film Phoenix Dance follows...[the creation] of a special pas de deux designed for a one-legged man and...`,
        author: "The New York Times"
    },

    {
        text: `The camera&apos;s gaze may be calmly intrigued as in Karina Epperlein&apos;s elegant Phoenix Dance, focusing on the wonderfully lyrical Homer Avila...`,
        author: "Deborah Jowitt", pub: "The Village Voice"
    },

    {
        text: `Epperlein has created one of those rare poignant films that touches the very depths of the soul and dwells within.`,
        author: "Darla Dixon", pub: "Hot Springs Documentary Film Festival Coordinator"
    },

    {
        text: `After the committee watched &ldquo;Phoenix Dance&rdquo;, there was spontaneous applause by everyone!! An absolute must.`,
        author: "Pat Ferrero", pub: "Mendocino Film Festival Program Director"
    },

    {
        text: `What an incredible film. It is so moving, touching, breath-taking, and we don&apos;t know what else. Words fail to describe the effect on us. `,
        author: "Stuart Dempster", pub: "composer/performer; Renko, visual artist"
    },

    {
        text: `Unsentimental and deeply moving, Phoenix Dance reveals Avila&apos;s unwavering strength, as well as his tender and playful nature, in the face of reinventing 
        himself as a &ldquo;one-legged&rdquo; artist.`,
        author: "Dance Magazine"
    },

    {
        text: `I loved the film – it was very inspirational and the story was beautifully told and shown.`,
        author: "Bob Russell", pub: "The Little Theatre Film Society"
    },

    {
        text: `I was blown away. Not only is it an amazing story but the dancing and your filming & editing of it are gorgeous.`,
        author: "Deirdre McGrath", pub: "Community Resources for Independence"
    },

    {
        text: `A beautiful film about a heroic journey involving dance, cancer and the determination to make the most of your life.`,
        author: "Linda B. Broker", pub: "Rocky Mountain Women's Film Festival"
    },

    {
        text: `Dance is a performing art, and much of its live energy gets lost when transferred to film. Its power, however, remains in Epperlein&apos;s work. Phoenix Dance is not a lamentation or a ploy to evoke sympathy from the audience. [T]he film transcends Avila&apos;s story and speaks to the meaning of true artistry.`,
        author: "From eclpse.livejournal.com"
    },

    {
        text: `Congratulations are in order for Bay-area filmmaker Karina Epperlein and her beautiful and inspiring documentary short Phoenix Dance. We keep our fingers crossed that this extraordinary work gets even further recognition from the Motion Picture Academy... its&apos; hard to imagine that there are five better.`,
        author: "Matthew Curtis", pub: "Enzian Theatre, Florida Film Festival"
    },

    {
        text: `Thank you for bringing Homer, Alonzo and Andrea into our lives so that we too can look beyond our obstacles and can learn who we are meant to be!`,
        author: "Elizabeth Gould Fuss", pub: "Walach Glass Studio"
    },

    {
        text: `Just saw it at the Denver Film Festival. So strong. So stunning. So simple. It left me quiet inside. Thanks so much.`,
        author: "Pilar Baca"
    },

    {
        text: `Phoenix Dance will have you in equal parts awe and regret. Awe for the power of the human potential to rise up and overcome and regret for every silly, stupid, 
            whiney complaint you&apos;ve ever tendered.`,
        author: "advocate, UC Denver"
    }
];

const awards: AwardRecord[] = [
    {
        festival: "Rocky Mountain Women&apos;s Film Festival",
        award: "2006 Madelyn&apos;s Choice Award"
    },
    {
        festival: "[ida] DocuWeek Theatrical Showcase",
        award: "2006 Official Selection"
    },
    {
        festival: "San Francisco Int&apos;l Film Festival",
        award: "2006 Golden Gate Award"
    },
    {
        festival: "Emotion Pictures, Documentary & Disability International Festival, Athens, Greece",
        award: "2007 Prize for Orginality and Innovative Direction"
    },
    {
        festival: "Sydney Film Festival, Australia",
        award: "2007 Audience Award <strong>Best Short Film</strong>"
    },
    {
        festival: "Newport Beach Film Festival",
        award: "2007 Jury Award, <strong>Best Short Documentary</strong>"
    },
    {
        festival: "(The Izzies) Special Achievement",
        award: "2007 Isadora Duncan Dance Award"
    },
    {
        festival: "FOCUS Film Festival, California",
        award: "2008 Spirit of the Festival"
    },
    {
        festival: "look&roll Kurzfilmfestival, Switzerland",
        award: "2008 Audience Award"
    },
    {
        festival: "SAPPORO Short Fest, Japan",
        award: "2008 <strong>Best Documentary</strong>"
    },
    {
        festival: "Superfest International Disability Film Festival, Berkeley, California",
        award: "2008 Merit Award"
    },
    {
        festival: "Black Maria Film & Video Festival",
        award: "2008 <strong>Grand Prize</strong>, Best of Festival, Documentary"
    }
];

interface PhoenixArtistRecord {
    intro: string;
    image: string;
    poem: string;
    statement: string;
}
const artistStatement: PhoenixArtistRecord = {
    intro: `Aren&apos;t there days when we wake up with no hope, feeling crippled in visible and invisible ways, longing to overcome our limitations of the heart? 
        And then suddenly, to our surprise, we are touched and find the courage to live life with all its possibilities, life in all its beauty.`,
    image: "artist-statement.jpg",
    poem: `The body his instrument, his legs and feet so essential.<br />
        What if he is missing one leg?<br />
        Is dancing still possible?<br />
        Could he dance with one leg?<br />
        Homer does just that &mdash; he dances with one leg.<br />
        No crutches, nothing.<br />
        And he makes it look like the most beautiful thing on earth.<br />
        He is flying, soaring...`,
    statement: `<p>When I saw Homer perform with Andrea Flores in October 2002 at an AXIS Dance Company performance, I had tears streaming down my face, and I urgently asked 
        myself how I could help to bring this utterly inspiring effort to the world. Being a filmmaker I asked Homer if he would be interested in a film about himself and 
        this particular pas de deux which, for me, spoke about the essence of the human experience. I knew nobody would be able to imagine a dancer on one leg like Homer 
        without seeing it. He said yes, and took me on a journey too.</p>
        
        <p>With my film Phoenix Dance, I wanted to stay simple, unsentimental, close to my initial enthusiasm and inspiration allowing the audience to come along with their 
        own feelings.</p>
        
        <p>Homer&apos;s example inspires us to live our own &ldquo;despite.&rdquo; His one-legged-ness and vulnerability take away our breath. He becomes a new being, dancing beautifully, 
        and nothing is missing. Life has shaped and molded him. As Alonzo says, in order to overcome hardship we have to come forth with who we really are. Reach beyond our perceived 
        and real limitations of body and heart. Homer is doing just that. Healing is a journey for all of us, and &mdash; as with Homer &mdash; it moves us toward wholeness.</p>
        
        <p>My work has always aspired toward the themes of transformation and healing; not shying away from looking into the dark corners, finding the light. I bring to Phoenix Dance 
        more than my background as a documentary filmmaker. I am drawn to it by my previous experience as a professional dancer, choreographer, performer and director in theater, and 
        my lifelong work in movement, creative expression and healing, including 25 years as a teacher of T&apos;ai Chi, breath and sound. No matter which medium I am working in, 
        I strive to translate my inner poetic language into images that are emotionally charged and speak to the audience on a visceral level, taking them on a journey.</p>`
}

// <img style="float: left; width: 36%; max-width: 270px; height: auto; margin-right: 8px" src="/graphics/films/artist-statement.jpg" /><p style="text-align: center; line-height: 32px;">Imagine a dancer:<br />
const pressRelease = `<p style="text-align: center">
    For Immediate Release<br />
    <strong>PHOENIX DANCE</strong><br />
    A heroic journey from loss to faith, trust and beauty<br />
    A new film by Karina Epperlein
    </p>
    <p>The film &ldquo;Phoenix Dance&rdquo; shows us the beauty and strength of one individual who defies our expectations of what it means to be &ldquo;disabled.&rdquo; 
    In March 2001, Homer Avila &mdash; who had been dancing with Twyla Tharp, Bill T. Jones, and Mark Morris &mdash; discovered that the pain in his hip was cancer. 
    One month later, his right leg and most of his hip were amputated. What unfolds is the story of the pas de deux called &ldquo;Pas&rdquo;, which the renowned 
    Alonzo King choreographed for Homer, now missing one leg.</p>

    <p>The film is an intimate poem, revealing that when heart and will are joined, the impossible happens. Through interviews, studio rehearsals, and theatre performances, 
    we witness a deeply moving collaboration. Interdependence, trust, and the process of &ldquo;strength-building&rdquo; by overcoming challenges in life, come visually alive. 
    These themes could not be expressed more poignantly than in a duet for a female and a male one-legged dancer. In their dance, a creature with three legs and four arms emerges. 
    The traditional roles are reversed: the man&apos;s vulnerability and the woman&apos;s strength complement each other, sweetly. And in their solo outbursts they spur each 
    other on to great heights. &ldquo;For me a pas de deux is a microscopic look into relationship,&rdquo; Alonzo King says in the film, &ldquo;and relationship could mean you 
    with yourself... It could mean a part of you that&apos;s dying. It could be you and your God, you and nature... wherever there is two negotiating or becoming one or 
    struggling.&rdquo;</p>

    <p>Three years after his amputation, Homer died on March 26, 2004 of re-occurring cancer in the lungs; he was 48. He had disclosed his decision to keep dancing and not go 
    into treatment to only a few people. While Homer&apos;s death did not affect the making of the film, it only made it more essential that his legacy live on. 
    He spent the last three years of his life doing what he loved most: dancing &mdash; one-legged &mdash; on stages around the world in pieces choreographed for him not only 
    by Alonzo King, but by Victoria Marks and Dana Casperson of the Frankfurt Ballet. The urgency in Homer&apos;s dancing, rehearsing, traveling and lecturing was palpable. 
    He inspired all who came in contact with him.</p>`;

const pressList =
    `<p>
    <a href="https://karinaland.com/graphics/films/press/nyt.pdf" target="_blank" rel="noopener noreferrer">
        <span style="color: ${app.themes.goldColor};">New York Times</span>, Movie Details, <i>November, 2006</i></a><br />
    <a href="https://karinaland.com/graphics/films/press/statesman.pdf" target="_blank" rel="noopener noreferrer">
        <span style="color: ${app.themes.goldColor};">Statesman Journal</span>, <i>October 29, 2006</i></a><br />
    <a href="https://karinaland.com/graphics/films/press/academy.pdf" target="_blank" rel="noopener noreferrer">
        <span style="color: ${app.themes.goldColor};">Academy Short-list Press Release</span>, <i>October 11, 2006</i></a><br />
    <a href="https://karinaland.com/graphics/films/press/sentinel.pdf" target="_blank" rel="noopener noreferrer">    
        Article in <span style="color: ${app.themes.goldColor};">The Sentinel Record,</span> Arkansas, <i>September 21, 2006</i></a><br />
    <a href="https://karinaland.com/graphics/films/press/democrat.pdf" target="_blank" rel="noopener noreferrer">
        <span style="color: ${app.themes.goldColor};">Democrat and Chronicle,</span> Rochester, <i>September 14, 2006</i></a><br />
</p>`

const screenings: ScreeningYearRecord[] = [
    {
        year: "2006",
        screenings: [
            { venue: "January 2006, Premiere, Dance On Camera Festival, at Lincoln Center, New York" },
            { venue: "Florida Film Festival" },
            { venue: "Nashville Film Festival" },
            { venue: "Crossroads Film Festival (Mississippi)" },
            { venue: "San Francisco Int&apos;l Film Festival", award: "2006 Golden Gate Award" },
            { venue: "Cinedans Int&apos;l Dance Film Festival, Amsterdam" },
            { venue: "American Dance Festival Dancing for the Camera (Durham, North Carolina)" },
            { venue: "Flint Institute of Arts" },
            { venue: "Jacob&apos;s Pillow Dance Festival" },
            { venue: "Rialto Cinemas Lakeside, Disability Series (Santa Rosa, California)" },
            { venue: "Int&apos;l Ballet Festival of Miami" },
            { venue: "The Rochester Dance On Camera Festival" },
            { venue: "IDA&apos;s DocuWeek Theatrical Documentary Showcase in Los Angeles" },
            { venue: "Atlantic Film Festival (Nova Scotia)" },
            { venue: "Mill Valley Film Festival" },
            { venue: "DocuWeek Multi City Theatrical Rollout (eight cities from Sept – Nov)" },
            { venue: "Hot Springs Documentary Film Festival" },
            { venue: "Heartland Film Festival" },
            { venue: "Festival of the German Dance Archive in Cologne (Germany)" },
            { venue: "15th Moving Pictures Festival of Dance on Film and Video, Toronto (Canada)" },
            { venue: "Rocky Mountain Women&apos;s Film Festival", award: "Madelyn&apos;s Choice Award" },
            { venue: "29th Starz Denver Film Festival" },
            { venue: "Friends of Film, Palisades" }
        ]
    },
    {
        year: "2007",
        screenings: [
            { venue: "Palm Springs Int&apos;l Film Festival" },
            { venue: "Santa Barbara Int&apos;l Film Festival" },
            { venue: "Everett Women&apos;s Film Festival, WA" },
            { venue: "Constellation Change Screen Dance Festival, London, Curzon Soho (England)" },
            { venue: "Ashland Film Festival" },
            { venue: "Sonoma Valley Film Festival" },
            { venue: "Newport Beach Film Festival", award: "2007 Jury Award, Best Short Documentary" },
            { venue: "Washington Center for the Performing Arts, Dance on Film Festival" },
            { venue: "Mendocino Film Festival, California" },
            { venue: "Sydney Film Festival, Australia", award: "2007 Audience Award, Best Short Film" },
            { venue: "VSA arts Family Festival, Kennedy Center" },
            { venue: "Emotion Pictures, Documentary & Disability, Athens (Greece)", award: "2007 Prize for Originality and Innovative Direction" },
            { venue: "Milano Doc Festival (Italy)" },
            { venue: "Port Townsend Film Festival, Oregon" },
            { venue: "“The Way We Live” (wie wir leben) Int&apos;l Short Film Festival, Munich (Germany)" }
        ]
    },
    {
        year: "2008",
        screenings: [
            { venue: "FIFA, Festival for Films on Art, Montreal (Canada) (March &apos;08)" },
            { venue: "Black Maria Video and Film Festival", award: "Grand Prize, Best of Festival, Documentary" },
            { venue: "Wolverhampton Disability Film Festival (England)" },
            { venue: "Int&apos;l Festival of Short Films on Culture, Jaipur (India)" },
            { venue: "Fargo Film Festival, North Dakota" },
            { venue: "Contemporary Documentary Series, Academy of Motion Picture Arts and Sciences, Hollywood" },
            { venue: "Superfest, Int&apos;l Disability Film Festival, Berkeley, California", award: "Merit Award" },
            { venue: "The Other Film Festival, Melbourne (Australia)" },
            { venue: "look&roll, Basel, Switzerland", award: "Audience Award" },
            { venue: "Sapporo Short Fest", award: "Best Documentary (Japan)" },
            { venue: "FICAP, Int&apos;l Film Festival of Performing Arts, National Theatre Museum, Lisbon (Portugal)" },
            { venue: "Yerevan Film Institute (Armenia)" },
            { venue: "The Other Film Festival, Melbourne (Australia)" },
            { venue: "Abilities Arts Festival, Toronto (Canada)" },
            { venue: "FOCUS Film Festival, California", award: "2008 Sprit of the Festival" },
            { venue: "Int&apos;l Disability Film Festival, Moscow (Russia)" },
            { venue: "1001 Documentary Film Festival, Istanbul (Turkey)" },
            { venue: "Dance Film Festival for Cultural Olympiad in Vancouver (Canada)" }
        ]
    },
    {
        year: "Television",
        screenings: [
            { venue: "Aired on KQED, and public TV in Greece, Sweden and Brazil" }
        ]
    }
];

const castAndCrew: string[] = [
    `<p><span style="color: ${app.themes.goldColor}; font-weight: bold;">HOMER AVILA</span> connected with dance as his life&apos;s passion while studying at the 
    University of Tennessee at Knoxville. Prior to dance, his love of movement was realized through competitive gymnastics in his hometown of New Orleans. After moving to New York, 
    Homer performed with Twyla Tharp, Mark Morris, Ralph Lemon and the Bill T. Jones/Arnie Zane Dance Company. Homer was known best for work with Avila/Weeks Dance, a modern-dance 
    company he directed with Edisa Weeks. His pieces covered a wide variety of topics, standing out for the most part for strong visual imagery.</p>
    <p>Homer&apos;s choreography was commissioned by Adelphi University, Ailey II, Hofstra University, NYU's Washington Square Repertory Ensemble, and Agnes Scott College. His work has 
    been performed at the Guggenheim Museum, Massachusetts International Festival of the Arts, the National Black Arts Festival, and Summer Stages Dance. He taught at, the Alvin Ailey 
    American Dance Center, Oberlin College, Spelman College and Wesleyan College.</p>
    <p>In 2001 Homer was diagnosed with chondrosarcoma, a rare form of cancer; shortly thereafter, his right leg and hip were amputated. Buoyed by the support of the worldwide dance 
    community, Homer returned to dance seeking to further explore his potential. Out of that exploration came his &ldquo;Not/Without Words&rdquo; and . “(Body of) Works In Progress”. He also 
    performed new work choreographed by Vicky Marks, Alonzo King, and Dana Casperson at P.S.122, Ballett Frankfurt, the Millennium Stage at the Kennedy Center and other venues. 
    At the time of his death on April 25, 2004, Homer was creating a solo for Cleveland&apos;s Dancing Wheels dance company.</p>`,
    `<p>Bay Area dancer <span style="color: ${app.themes.goldColor}; font-weight: bold;">ANDREA FLORES</span> has performed with Alonzo King's Lines Contemporary Ballet Company, 
    Kunst-Stoff Dance Troupe, Lawrence Pech Dance Company, San Francisco Ballet, Smuin Ballet, and most recently, The Foundry. Andrea teaches ballet, jazz, and hip-hop at the 
    Debbie Allen&apos;s Early Bird Summer Intensive, the LINES Ballet School&apos;s Pre-Professional Program, and the Rhythm and Motion Dance Center in San Francisco. Andrea is also a 
    professional photographer specializing in the performing arts and special events.</p>`,
    `<p><span style="color: ${app.themes.goldColor}; font-weight: bold;">ALONZO KING</span> has works in the repertories of companies throughout the world including Frankfurt Ballet, 
    Joffrey Ballet, Dance Theater of Harlem, Alvin Ailey American Dance Theatre, and Hong Kong Ballet. He has worked extensively in opera, television, and film and has choreographed 
    works for prima ballerina Natalia Makarova and film star Patrick Swayze. Renowned for his skill as a teacher, Mr. King has been the guest ballet master for National Ballet of 
    Canada, Les Ballets de Monte Carlo, San Francisco Ballet, and others.</p>
    <p>In 1982, Mr. King founded Alonzo King's LINES Ballet, which has developed into an international touring company. Seven years later, he inaugurated the San Francisco Dance 
    Center, which has grown into one of the largest dance facilities on the West Coast. In 2001, Alonzo King started the LINES Ballet School and Pre-Professional Program to nurture 
    and develop the talents of young dancers.</p>
    <p>Alonzo King is a recipient of the NEA Choreographer's Fellowship, Irvine Fellowship in Dance, and the National Dance Residency Program. He has received five Isadora Duncan 
    Awards. Alonzo King has served on panels for the National Endowment of the Arts, California Arts Council, City of Columbus Arts Council and Lila Wallace-Reader's Digest Arts 
    Partners Program. In 2005 he was named a Master of African-American Choreography by the Kennedy Center. He is a former commissioner for the city and county of San Francisco, 
    and a writer and lecturer on the art of dance.</p>`,
    `<p>A native of Germany, <span style="color: ${app.themes.goldColor}; font-weight: bold;">KARINA EPPERLEIN</span> <i>[Producer/Director/Cinematographer/Editor]</i> has worked 
    in Europe and the U.S. for the past thirty years as a theater artist, teacher, and filmmaker. Her earlier performance videos Labyrinthian (1984) and i.e.Deutschland (1988) were 
    followed by her award-winning documentary Voices From Inside (1996) about women in prison and their children outside. Karina also directed Women's Rites (2000), a video about 
    dancer Anna Halprin's Expressive Arts Therapy. Her film I Will Not Be Sad In This World (2001) is a lyrical portrait of a 94-year-old survivor of the 1915 Armenian genocide. 
    In 2003 Karina directed We Are Here Together (2003), a film about the temptestuous first year of an alternative charter high school in Alameda, California. Her latest film 
    Phoenix Dance is about a one-legged dancer&apos;s return to the stage.</p>`,
    `<p>An independent filmmaker since 1967, <span style="color: ${app.themes.goldColor}; font-weight: bold;">JOHN KNOOP</span> <i>[Cinematographer]</i> has also been an active 
    cinematographer with extensive documentary credits including Louie-Bluie, Maria's Story, Poison in the Rockies, Thanh's War, and Where the Heart Roams. Among the films Knoop 
    has produced and directed are The Legacy of Argentina&apos;s Dirty War, River Out of Time, Report from Iraq, Shadow Master, Memories of the Hunt, and Sea of Cortez. From 1990 
    through 1996 he worked with correspondent Elizabeth Farnsworth on 62 short documentary reports from Asia, Latin America and the U.S. for the MacNeil/Lehrer News Hour. In 
    2003 John co-produced (with Karina Epperlein) We Are Here Together, a film about an alternative charter high school in Alameda, California.</p>`,
    `<p>Filmmaker <span style="color: ${app.themes.goldColor}; font-weight: bold;">GINA LEIBRECHT</span> <i>[Associate Producer/Editor]</i> has collaborated with acclaimed 
    documentary filmmaker Les Blank on several projects, including editing White Feathers, Black Bones and Native Glory, a film about the whimsical art collector Rene di Rosa. 
    She recently edited Frank Green&apos;s Counting Sheep; David L. Brown&apos;s Of Wind and Waves: The Life of Woody Brown; and Larry Reed&apos;s Explorations of the Shadow World. Gina is 
    currently working with Lynn Hershman on her documentary Changing Worlds about the history.</p>`
];
const gallery = [
    "phoenix-gallery-homer-alonzo.jpg",
    "phoenix-gallery-homer-at-bar.jpg",
    "phoenix-gallery-leg-out.jpg",
    "phoenix-gallery-leg-up.jpg",
    "phoenix-gallery-still.jpg",
    "phoenix-gallery-muted.jpg",
];

const data: FilmDataRecord[] = [
    {
        caption: "synopsis",
        recordType: FilmDataRecordType.text,
        data: introText
    },
    {
        caption: "awards",
        recordType: FilmDataRecordType.awards,
        data: awards
    },
    {
        caption: "press quotes",
        recordType: FilmDataRecordType.pressQuotes,
        data: reviews
    },
    {
        caption: "artist's statement",
        recordType: FilmDataRecordType.custom,
        data: ''
    },
    {
        caption: "press release",
        recordType: FilmDataRecordType.text,
        data: pressRelease
    },
    {
        caption: "press",
        recordType: FilmDataRecordType.press,
        data: pressList
    },
    {
        caption: "screenings",
        recordType: FilmDataRecordType.screenings,
        data: screenings
    },
    {
        caption: "cast and crew",
        recordType: FilmDataRecordType.castAndCrew,
        data: castAndCrew
    },
    {
        caption: "photo gallery",
        recordType: FilmDataRecordType.photoGallery,
        data: gallery,
        centerRectangle: true
    }
];
const PhoenixDance: React.FC = () => {
    return (
        <FilmPage data={data} title="Phoenix Dance" trailer="63475130?h=cc976739f4" customComponent={RenderArtistStatement} />
    )
}
//-----------------------------------------------------------------
const ImageAndPoemContainer = styled.div`
    display: flex;
    align-items: center;
`
const ImageDiv = styled.div`
    width: 38%;
    margin-right: 32px;
`
const StatementImage = styled.img`
    width: 100%;
    height: auto;
`
const PoemText = styled.p`
    text-align: center;
    line-height: 36px;
`
const RenderArtistStatement: React.FC<{ caption: string }> = (props) => {
    // to retrieve caption: props.caption
    return (
        <React.Fragment>
            <p dangerouslySetInnerHTML={{ __html: artistStatement.intro }} />
            <ImageAndPoemContainer>
                <ImageDiv>
                    <StatementImage src={formatImageUrl(artistStatement.image, { graphicsSubfolder: "films" })} />
                </ImageDiv>
                <PoemText dangerouslySetInnerHTML={{ __html: artistStatement.poem }} />
            </ImageAndPoemContainer>
            <div dangerouslySetInnerHTML={{ __html: artistStatement.statement }} />
        </React.Fragment>
    )
}

export default PhoenixDance;
