import React from 'react';
import styled from 'styled-components';

import { formatImageUrl } from '../../libs/ImageFormatter';
import FilmPage, { FilmDataRecord, FilmDataRecordType, ReviewRecord, ScreeningYearRecord } from './FilmPage';

const introText = `<strong>Voices From Inside</strong> follows a German born theater artist into a federal women&apos;s prison. 
    She encourages a racially mixed circle of women to find their own voices through poetry and creative expression, and meets their children on the outside. 
    The prisoners tell their life stories and share their experience of prison life. <strong>Voices From Inside</strong> shatters conventional stereotypes about crime, criminals 
    and race. An inspiring experiment in breaking the walls with love! <strong>Voices From Inside</strong> won the National Council on Crime and Delinquency&apos;s PASS Award`;

const reviews: ReviewRecord[] = [
    {
        text: "A stunning film! It gives a very valuable picture of how women come to be in prison, and the futility of keeping them there.",
        author: "Leslie Acoca, senior researcher, National Council on Crime & Delinquency"
    },
    {
        text: `Every legislature that lavishes money on building prisons &mdash; and starves programs that rebuild dignity &mdash; should be put in a room with this unforgettable 
        story of four women&apos;s lives.`,
        author: "Gloria Steinem"
    },
    {
        text: `I found it beautifully made and very moving. It is so important for us on the outside to realize what &apos;treasures&apos; are hidden away on the inside.`,
        author: "Susan Sarandon"
    }
];

const screenings: ScreeningYearRecord[] = [
    {
        screenings: [
            { venue: "Film Arts Foundation Festival, San Francisco, 1996", award: "Premiere" },
            { venue: "American Sociological Association" },
            { venue: "PARNU Int&apos;l Documentary Film Festival (Finland)" },
            { venue: "Charlotte Film & Video Festival" },
            { venue: "Windy City Int&apos;l Documentary Festival", award: "Honorable Mention" },
            { venue: "National Women&apos;s Studies Association" },
            { venue: "American Anthropological Association (Annual Meeting)" },
            { venue: "Denver Int&apos;l Film Festival" },
            { venue: "Arizona Int&apos;l Film Festival" },
            { venue: "USA Film Festival", award: "Finalist" },
        ]
    }
];

const updates = `<p><strong>Aida McCray Robinson</strong> founded her own non-profit, &ldquo;Families with a Future,&rdquo; to provide emotional support groups and grants to 
children and families with incarcerated mothers. She received the Violence Prevention award in 1999 from the California Wellness Foundation. 
She graduated from San Francisco State University and is currently working on family reunification and parenting at the SF County jails.</p>
<p><strong>Dylcia Pagan</strong> and eleven other Puerto Rican political prisoners were given clemency in September of 1999. She moved to Puerto Rico.`

interface VoicesPressRecord {
    header: string;
    subheader?: string;
    author: string;
    authorBio?: string;
    pub: string;
    image: string;
    text: string;
}
const yesMagazine: VoicesPressRecord =
{
    header: "Is It Time to Close the Prisons?",
    subheader: "Cry Out: Women Behind Bars",
    author: "Christine Schoefer",
    authorBio: "Christine Schoefer is a freelance writer living in Berkeley, CA",
    pub: "Yes! Magazine, Fall 2000",
    image: "yes-magazine.jpg",
    text:
`
<p>The visiting rooms in women's prisons are usually empty. The men don't come. Children don't see their mothers because nobody is willing to bring them.</p>
<p>"I miss my kids," says Ida, a middle-aged mother of five. “That’s what hurts most.” Ida is watering the daisies she planted at the Federal Correctional Institution in Dublin, California.<p>
<p>Nearby, her best friend stoops to pull weeds. She’s in her late 40s and has been behind bars for 12 years on drug charges. “This is what my life is about,” she says, “ripping out weeds. It keeps me alive.”<p>
<p>The two look up as a voice calls them into the recreation hall, known to inmates as “the barn.” As they enter, a 23-year-old woman, also in on drug charges, joins them. A sad-eyed 50-year-old enters the room next. “I’ve been waiting for this class all week,” she says loudly.<p>
<p>“I’m ready to scream,” agrees another inmate, smiling.<p>
<p>An older Chinese woman gently lowers herself to the carpeted floor. Like many women at the Dublin prison, she’s serving time for a drug conviction. “Worked hard today,” she sighs, turning her attention to a slight, brown-haired woman. “I’m tired, I’m stressed. But I’m free now that I’m here with you, Miss Karina.”<p>
<p>Karina Epperlein is the only woman who has come to the prison by choice. She’s here to spend the day teaching “soul work,” trying to help imprisoned women feel free.<p>
<p>Epperlein is a native of Germany, a theater artist and t’ai chi instructor. Her simple instruction in breathing, poetry, voice, and song unlocks capacities for artistic expression, strength, and self-esteem that these women didn’t know they had and helps them cope emotionally and spiritually with life behind bars.<p>
<p>A circle of trust<p>
<p>Epperlein opens each class with the women facing each other in a circle, airing their grievances and frustrations. Next, she leads the women in stretching exercises, encouraging them to take deep breaths, sigh, and make whatever sound they feel like. Then she might ask them to express their energy with a good scream, encouraging them to feel the emotion of their voices vibrating in their chests.<p>
<p>“I want us to find a common ground, and sound is a good way to do that,” she says. “I really want them to express their pains, their angers, their joys, their cultural backgrounds, their ancestors.”<p>
<p>What emerges are haunting noises. “It felt like something was dying,” says one woman, after listening to another. “It was like she was letting some death out of her.” Often the sounding off awakens memories, which Epperlein asks the women to weave into stories, poems, or drawings.<p>
<p>Epperlein quickly became known as “the screaming lady.” The nickname enhanced her reputation, and eventually the women were bringing all kinds of emotions to the barn: the pain of loss and separation, anger at being locked up and alone.<p>
<p>The Dublin prison was originally built for 300 inmates, but at times it houses four times that many. Most women are packed three to a cell, sharing a space the size of a bathroom. The toilet and sink are next to the beds.<p>
<p>“I feel like the living dead,” Ida says. “Got a mama, can’t touch her; babies, can’t love them. The only thing I feel is freaked out inside. Useless to the ones I love. What good is it to live like this? Why should anyone have a life so useless?”<p>
<p>The fastest-growing prison population<p>
<p>Women make up between 6 and 8 percent of the state and federal prison populations nationwide, but the number of women entering prisons quadrupled between 1980 and 1994, a rate far exceeding the growth in the male prison population. In 1999 the number of women inmates in the US reached 146,000. A 1996 report by the National Council on Crime and Delinquency called the jump in the number of incarcerated women a “hidden crises,” a disaster obscured by the unprecedented growth of the total prison population.<p>
<p>Nearly 75 percent of women in prison today are there for nonviolent crimes, often petty drug or property offenses that would not likely have warranted prison time prior to the war on drugs of the 1980s. The three-strikes law had an unforeseen effect on the women’s prison population. Many of the women at the Dublin prison took the rap to protect their sons or their lovers from a second or third strike.<p>
<p>A Council study found that the overwhelming majority of imprisoned women were caught in a spiral of poverty, racism, abuse, and neglect long before entering the prison system. Half of them were in trouble as girls. Nearly a third say the income they made the year prior to their arrest was not enough to support themselves and their children. A history of drug and alcohol dependence is common, as is a family history of arrest and imprisonment.<p>
<p>More than 90 percent of women in prison report that they suffered physical, sexual, or emotional abuse as children and/or as adults, and Epperlein says prison’s “totalitarian” structure reinforces feelings of isolation and fear, exacerbating habits of self-destruction. Most difficult of all, many of these women are mothers.<p>
<p>Mothers behind bars<p>
<p>Almost 80 percent of imprisoned women are mothers. Most of them are single. Nearly 1.3 million minor children have mothers under supervision by justice system agencies.<p>
<p>&ldquo;Pain is seeing your children grow, your children wanting and needing you, and you needing and wanting them,&rdquo; says Ida, who is serving 20 years for her part in her boyfriend’s hijacking of a plane in the early Ã”70s. “Pain is having to say too often in a letter, Ã”I love you, and not being able to be there to comfort when they need you.”<p>
<p>“We don’t get to feel our pain,” Dylcia says. “We don’t get to express that we’re mothers, that we’re women, that we have lovers on the outside.” Dylcia is serving a 55-year sentence for seditious conspiracy for her involvement with the Puerto Rican nationalist movement.<p>
<p>Once inside, these women are more or less forgotten. Epperlein noticed that while women regularly visit their men in prison, often bringing their children along, the visiting rooms in women’s prisons are usually empty – the men don’t come. Children don’t see their mothers because nobody is willing to bring them.<p>
<p>“I used to think that being alone was great,” says Florencia, 44-year-old mother of five. “But now I have too much time in solitude, too much time to ache. Can’t see my babies laugh or sing. Can’t be there when my children need me to be.”<p>
<p>Ida describes seeing her kids in the visiting room: “You’re used to having a relationship with them, and then they put you behind bars, and they’ve got this big glass between you and, you know, they can’t touch you.”<p>
<p>For a child, losing a mother to prison is traumatic. “When your mom is taken away,” Epperlein says, “you believe that she’s bad, you’re bad, nothing makes sense.”<p>
<p>“Breaking the walls with love”<p>
<p>“I am so sick of being here that all I have to do is think about it and cold chills run through my body and I get real teary-eyed,” says Evangelina, who is serving five years for trying to rob a bank unarmed.<p>
<p>In the beginning, Epperlein encouraged the women to put thoughts like these to paper. But she says women would often come to class sick from the food or the lack of heat in the prison. She quickly learned that, given the conditions, completing a thought, let alone a writing assignment, was a major accomplishment.<p>
<p>In spite of the challenges, Epperlein’s group exceeded her expectations. They developed an awareness of the cost of shutting down their emotions, and of the liberation that comes with exploring them. They learned to release anger and frustration, to talk about fear, and to open up to each other.<p>
<p>“At times I get really, really angry,” explains Florencia, who is serving 14 years for drug possession. “But now I have the art and the singing. That helps a lot. When I get to where I’m crying and I want to scream and I want to break down the door and all that, I usually start singing and it gives me a lot of peace. There’s the inner strength I didn’t know I had and now I know I have it and I’ll put it to good use.”<p>
<p>Epperlein’s classes came to an end in 1996 following a statewide cutback meant to ensure that punishment take precedence over rehabilitation in prison. But she preserved her experiences in a documentary film, <strong>Voices From Inside</strong>. Through a combination of interviews, scenes from class work, and excerpts from a performance of an original theater piece created out of four inmate’s own poetry, song, music, and dance, the film portrays the spiritual reawakening of women hardened by years of confinement. It reminds those on the outside that locked inside each prisoner is a woman still capable of joy, anger, love, and – especially – pain. “Many times I witnessed these women’s despair of being forgotten,” Epperlein says. “They feel buried alive.”<p>
<p>Epperlein says people grow uncomfortable when she talks about incarcerated women longing for their children and the pain of their isolation. It’s easier to talk about prisoners in sensationalist terms – gangs, drugs, and sex. There’s no denying that things happen – guards smuggle in drugs, women trade sex for favors – but Epperlein says dwelling on such stories perpetuates the notion that imprisoned women are monsters. Instead of mobilizing demands for rehabilitation, such notions feed the public’s fear of criminals.<p>
<p>With <strong>Voices From Inside</strong>, Epperlein hopes to end stereotypes of women in prison. Gang activity and violence inside women’s prisons are actually rare. Men commit 86 percent of the violent crimes in the country.<p>
<p>Epperlein watched inmates transcend circumstances they’d been bound to for a lifetime: lack of education, poverty, abuse, sexism, and racism. “They’re not victims,” she says. “They’re just amazing if you give them a safe place where they can claim who they are and find their roots.”<p>
<p>The danger of showing emotions inside the walls of prison is replaced with safety inside the circle of women. The women help each other find the courage to break through their inhibitions, to find their voices and project the pain and heartache they have stuffed deep inside. When a prisoner’s tears finally begin to flow, the other women in the circle gather close around, hug her, and sing to her.<p>
<p>Evangelina has the last word in the film. “Here and now,” she says, “is a time for healing of the heart. No more self-pity, but the healing of our world. Can’t you feel the pain around you? Can’t you hear the cry for help? Cry out for mercy. Cry out for peace. Don’t hold back and keep the pain within, cry. Cry out, and for God’s sake, let the love begin.<p>
`
}

const turningWheel: VoicesPressRecord = {
    header: "Film Review",
    author: "Susan Phillips",
    authorBio: "Susan Phillips lives in Philadelphia and is active in the prison rights movement. As an anti-death penalty activist and member of the Philadelphia Anarchist Black Cross, she is currently producing a video on one of the groups she is active with called Books Through Bars, which provides books and educational materials to indigent prisoners.",
    pub: "Turning Wheel, Journal of the Buddhist Peace Fellowship",
    image: "turning-wheel.gif",
    text: `<p>Imagine living in a bathroom with a complete stranger. Imagine being able to see your children only once every two years through a pane of glass. Imagine rude awakenings every two hours with the shock of a flashlight. That's part of what Karina Epperlein helps you to envision while showing you life on the inside of a federal prison.</p>
    <p><strong>Voices From Inside</strong> passionately portrays the stories of four courageous women serving time in a federal women's prison in California, and the journey one woman from the outside took to use her skills as an artist to create change by establishing "a safe place for the women to show emotion." Karina Epperlein, born in Germany, was "shocked" to learn of the use of the death penalty here in the United States. This catalyzed her to enter into a voluntary four-year teaching stint in the prison. She used a combination of visual art, sound, breath and movement therapy to help women prisoners enter, if only for a short time, a safe space together, where no one is telling them when to sleep, eat, drink, and work.</p>
    <p>In this captivating, intimately shot video, we are transported into a world few of us on the outside ever take the time to consider. And yet, an increasing number of people are spending time inside prison walls where privacy, even to take a shower, rarely exists. As the camera slips silently through the barbed wire fence, we learn of the women's individual stories, and we see their enormous tenacity to endure. Due to the racist underpinnings of the court system, a disproportionate number of women prisoners are African-American, Latina and Asian. Most could not afford a good lawyer but had to accept the inadequate efforts of a public defendant. The four prisoners featured in the movie are all women of color. And beware, these women are far from useless, far from being beaten down, and they don't at all fit the stereotype of infantilized female prisoners. <strong>Voices From Inside</strong> helps us understand some of the causes and conditions that brought these women to prison.</p>
    <p>Additionally, the video illustrates the existence of political prisoners here in the United States, something our government has hypocritically denied. Dylcia Pagan, a Puerto Rican independence activist sentenced to 55 years for "criminal conspiracy" speaks out as a wise and courageous mother and revolutionary, who has never abandoned her principles in the fight for her people's freedom. The other three women—Aida, Evangelina, and Florencia—also share their personal stories and the anguish they feel at being separated from their children. We are introduced to these women as our own sisters, mothers, and grandmothers. Most importantly, we are introduced to their eloquent children, and we see the tragedy that occurs daily in this country when the punishment of mothers is also the brutal punishment of their children.</p>
    <p>This documentary presents staggering figures. Eighty percent of women in prison are mothers, with an average of two dependent children. Seventy-five percent of women in prison have been convicted of nonviolent offenses; 36 percent have been convicted on drug charges, solely for possession. Most women serve more time than men for committing the same crime. With 1.5 million people in prison, women inmates are increasing at a rate far higher than men. This particular prison is so overcrowded that a cell built for one now holds three women. With the passage of the Crime Bill and so-called Anti-Terrorism Act of 1996 these numbers will increase, as will the time spent behind bars for each individual. That this video was made at all is a miracle. In the current climate not only would Epperlein have a hard time showing up with a video camera, she would probably be banned from teaching inside prison. This in itself makes this film a rare treasure.</p>
    <p>Epperlein urges us to act, as she asks us quite frankly, "What will you do to stop this?" Of course there are many paths to action. In this video, Epperlein chooses what she calls "pouring love into bones," in a world of nightsticks, flashlights, and barbed wire. "<strong>Voices From Inside</strong>" inspires and challenges us all on the outside to remember and connect with those living behind prison walls. As someone who works directly with the prisoner rights movement, I commend Karina Epperlein for providing us all with an excellent tool for organizing. I encourage you to buy or rent a copy and show it to a group.`
}
const teachingSociology: VoicesPressRecord = {
    header: "Film Review",
    author: "Janet Bogdan",
    pub: "Teaching Sociology Magazine",
    image: "teaching-sociology.gif",
    text: `<p><strong>Voices From Inside</strong> challenges the current direction of prison law that is moving rapidly toward vindictive rather than rehabilitative imprisonment. Karina Epperlein's sensitively rendered story of four women able to recover their humanity even inside prison walls, poignantly illustrates the positive, life-affirming value of creative rehabilitation as a path for prisoners to discover the roots of their rage, to gain insight into its sources, and to prepare themselves for a productive life upon release.</p>
    <p>Epperlein, a German native who has been living in the United States for 14 years, is a theater artist who volunteered her skills weekly from 1992 to 1996 at the Dublin, California Women's Federal Correctional Institution. <strong>Voices From Inside</strong> documents her four years with a multicultural group of inmates ad Dublin developing a program of instruction in, as she describes, "breathing, sound, movement, drawing, writing and sharing." Viewers quickly see that the program went far beyond this modest description. At first simply a retreat for the prisoners from the brutality that is prison life, their weekly sessions in the recreation hall ("the barn"), soon became an opportunity for creative release and new understandings of their rage as a means of coping with life behind bars. With Epperlein's support and encouragement from one another, the breathing became moans and screams, the sound became hums, rhythms, drumming, chants and songs, the movement, drawing and writing became creative dancing and poetry, all expressing the participants' anger and pain, their sadness and hope, their hidden strengths and emerging self-esteem. Thrilled with their new understandings and newly found talents, the participants organized an original theater piece of their own music, song, and dance that they presented during two nights for their fellow inmates and for prison volunteers. A poignant tribute to their ongoing transformations was the first question from a fellow inmate at the start of the question and answer period following the presentation: "Have (any of) you been incarcerated?"</p>
    <p><strong>Voices From Inside</strong> does a fine job of taking the viewer through the development of both the program and the self-described changes in the participants. Through on-screen interviews with four of the program participants, we learn about their lives, the experiences leading up to their incarceration, and the reasons for their anger and frustration with the prison system. Chief among the latter is separation from their families. Three of the four women have dependent children and the women's anguish at being separated from them as they grow is palpable: "I feel like the living dead;" "Got a mama, can't touch her, babies, can't love 'em;" "I can only see my kids through glass." Each begins to cry as she thinks and talks about her children. Epperlein interviews children of two of the prisoners at their homes. They speak of the difficulties of having a mother in prison, of not being able to touch her, of rarely even seeing her, of not having her around when they are troubled. One especially misses his mother's way of "calm(ing) your heart down," of "mak(ing) you so touchworthy."</p>
    <p>This is a hopeful, uplifting video, superbly edited and produced. <strong>Voices From Inside</strong> documents some of the possibilities of creative, rehabilitative programming for inmates. However, it does not shy away from recognizing what prison is: "having to do what they want," explains one participant; "It's no privacy, not even on my period. Prison is sleepless nights, flashlights every two and a half hours, peeping Toms watching your every move. Prison is having to endure, without becoming them." The prisoners' lives inside the prison are not shown or discussed here although pertinent facts and statistics about prisons and women in prison are mentioned and shown throughout the video. We learn that Dublin prison, for example, built for 300, houses 1200; its cells, intended for one, hold three. We learn that the 1.5 million prisoners in 1995 represent a three-fold increase overall since 1983, but a five-fold increase for women; that most women are in prison for nonviolent crimes. <strong>Voices From Inside</strong> puts flesh on these numerical bones: "How would you like livin' in a bathroom with someone you never knew?" "A circle of madness controls your life." We are jolted to hear that 80 percent of women in prison are mothers; most are single and have an average of two dependent children; to hear that 20,000 children in California, 1.5 million nationwide, have mothers in prison. Most cannot see their mothers &mdash; prisons are too far away from their mostly urban homes, and children have no one to bring them to visit. <strong>Voices From Inside</strong> connects human faces and human suffering with these numbers.</p>
    <p>It is in the life stories, the close look we have of four powerful, committed women, that <strong>Voices From Inside</strong> becomes so useful for sociology classes dealing with issues and concepts such as labeling, resocialization, gender, inequality, groups, deviance, crime and delinquency, social policy and families. Shown fairly early in an introductory class, for example, the video could be a frequent point of reference and basis for discussion for all of these topics. Unlike other, also excellent videos on women in prison &mdash; Through the Wire, for example, is a 1990 PBS P.O.V. documentary about the prison lives of three women convicted of politically motivated crimes who are serving time at the Lexington, Kentucky federal underground prison that uses isolation to break prisoners down, both physically and emotionally &mdash; <strong>Voices From Inside</strong> offers the lived experiences of four of the women in Epperlein's "circle of caring" and the experiences of their children. Their stories offer typically unavailable information describing prisoners, their pasts, their current lives, and their hopes and dreams. Students can connect real experience, real human beings, to the sociological analysis of gender inequality, or of social welfare and family policy: What will happen to Aida and her three dependent children when she is released and faces some of the new welfare policies that prohibit former prisoners from applying for welfare? What will happen when women like those we meet in the video who are gaining self-esteem and hope for their postprison lives face the employment discrimination when female ex-prisoners search for jobs?</p>
    <p>Questions instructors might use with <strong>Voices From Inside</strong> would vary depending upon what course they were teaching, and this video could be used in a wide variety of courses. However, for an introductory course that includes a unit on crime and punishment, some of the questions might include:</p>
    <ol><li>Like others who are in "total institutions," the women we learn about in <strong>Voices From Inside</strong> have undergone a process of resocialization. Describe some of their comments that indicate this resocialization—for example, what they have to be or do to survive in this prison.</li>
    <liThree of the women interviewed extensively in this video are African Americans, one is Puerto Rican. To what extent does this sample reflect the proportions of ethnicities and races of America's female prison population? How reflective of this population is the whole group of "circle of caring" participants? How has the female prison population changed over the past 30 to 40 years? What are some of the reasons for these changes? For example, what crime laws in recent years have contributed to these changes?</li>
    <li>Why does Karina Epperlein, the filmmaker, say she could not carry out such a program at Dublin prison if she were to try and start it now? Dublin prison is in California. What recent changes in the laws of that state affect who goes to prison, the length of their incarceration, and their prison experience?</li>
    <li>How are children and families affected by their mothers' imprisonment? Why do female prisoners receive fewer visitors while in prison compared to male prisoners? What policies could change this situation and what policies presently work against children visiting their mothers in prison? How will recent changes in welfare law affect women released from prison? For example, how will they contribute to or detract from their ability to effectively assume family responsibilities?</li>
    <li>What insights does sociology offer to understand what is happening, and what could be done to address the problems? Why would these women be considered deviant in American society? What reasons can you think of that explain why relatively little scholarly research has been done on women in prison?</li></ol>`
}
const data: FilmDataRecord[] = [
    {
        caption: "synopsis",
        recordType: FilmDataRecordType.text,
        data: introText
    },
    {
        caption: "quotes",
        recordType: FilmDataRecordType.pressQuotes,
        data: reviews
    },
    {
        caption: "screenings",
        recordType: FilmDataRecordType.screenings,
        data: screenings
    },
    {
        caption: "updates",
        recordType: FilmDataRecordType.text,
        data: updates
    },
    {
        caption: "Yes! Magazine",
        recordType: FilmDataRecordType.custom,
        data: ''
    },
    {
        caption: "Turning Wheel Journal",
        recordType: FilmDataRecordType.custom,
        data: ''
    },
    {
        caption: "Teaching Sociology",
        recordType: FilmDataRecordType.custom,
        data: ''
    }
];
const Voices: React.FC = () => {
    return (
        <FilmPage data={data} title="Voices From the Inside" trailer="63475132?h=e74fe2cca9" customComponent={RenderPress} />
    )
}
//-----------------------------------------------------------------
const PressHeaderText = styled.p`
    font-size: 34px;
    text-align: center;
    margin-bottom: 0;
`
const PressSubheaderText = styled.p`
    font-size: 24px;
    text-align: center;
    margin: 16px;
`
const TextDiv = styled.div`
    li {
        margin-bottom: 8px;
    }
`
const PubNameText = styled.p`
    font-size: 18px;
    text-align: center;
    margin-top: 8px;
`
const ItalicText = styled.p`
    font-style: italic;
`
const ImageDiv = styled.div`
    height: 167px;
    float: left;
    img {
        width: auto;
        height: 167px;
        margin-right: 16px;
    }
`
const RenderPress: React.FC<{ caption: string }> = (props) => {
    // to retrieve caption: props.caption
    let record: VoicesPressRecord;
    if (props.caption.startsWith("Yes")) {
        record = yesMagazine;
    } else if (props.caption.startsWith("Turning")) {
        record = turningWheel;
    } else {
        record = teachingSociology;
    }
    return (
        <React.Fragment>
            <PressHeaderText>{record.header}</PressHeaderText>
            {record.subheader && <PressSubheaderText>{record.subheader}</PressSubheaderText>}
            <PubNameText>from {record.pub}</PubNameText>
            <ItalicText>by {record.author}</ItalicText>
            <ImageDiv>
            <img src={formatImageUrl(record.image, { graphicsSubfolder: "films" })} />
            </ImageDiv>
            <TextDiv dangerouslySetInnerHTML={{ __html: record.text }} />
            {record.authorBio && <ItalicText dangerouslySetInnerHTML={{ __html: record.authorBio }} />}
        </React.Fragment>
    )
}

export default Voices;