import React from 'react';
import styled from 'styled-components';

import app from '../appData';
import IconButton from '../libs/IconButtonV2';

interface ButtonProps {
    caption: string;
    darkBackground?: boolean;
    height?: number;
    fontSize?: number;
    marginLeft?: number;    // default to 1em
    marginTop?: number;
    onClick: () => void;
}
const Button: React.FC<ButtonProps> = (props) => {
    const style = app.themes.buttonStyles;
    if (props.darkBackground) {
        style.backgroundColor = app.themes.backShadeColor;
    }
    if (props.marginTop) {
        style.marginTop = props.marginTop + "px";
    }
    style.fontSize = props.fontSize + "px";
    style.height = props.height + "px";
    return (
        <IconButton style={style} marginLeft={props.marginLeft} hoverStyle={app.themes.buttonHoverStyles} caption={props.caption} onClick={props.onClick} />
    )
}
//-----------------------------------------------------------------------------------
export enum HeaderFontSize { small = 'S', medium = 'M', large = 'L', custom = 'C' }
// margins default to 50
// fontSize: small=30, medium=32, large=34 (default)
// align: "center" (default) or "left"
// fontWeight: "normal" or "bold" (default)
export const StyledHeaderText = styled.p<{ fontWeight: string; fontSize: number; marginTop: number; marginBottom: number; align: string; shadow: string }>`
    font-size: ${props => props.fontSize}px;
    line-height: ${props => props.fontSize * 1.2}px;
    font-weight: ${props => props.fontWeight};
    text-align: ${props => props.align};
    margin-bottom: ${props => props.marginBottom}px;
    margin-top: ${props => props.marginTop}px;
    color: white;
    text-shadow: ${props => props.shadow};
`
interface HeaderTextProps {
    text: string;                   // set as html
    fontSize?: HeaderFontSize;      // default to large (34)
    customFontSize?: number;        // if given, overrides fontSize
    fontWeight?: string;            // default to bold
    marginTop?: number;             // default to 50
    marginBottom?: number;          // default to 50
    align?: string;                 // default to center
    shadowColor?: string;           // default to themes.textShadowRed
    shadowBlur?: number;            // used if shadowColor is passed; defaults to 6
}
export const HeaderText: React.FC<HeaderTextProps> = (props) => {
    let fontSize = props.customFontSize ? props.customFontSize : 34;
    if (props.fontSize) {
        if (props.fontSize === HeaderFontSize.small) {
            fontSize = 30;
        } else if (props.fontSize === HeaderFontSize.medium) {
            fontSize = 32;
        } else if (props.fontSize === HeaderFontSize.custom) {
            fontSize = props.customFontSize ? props.customFontSize : 34;
        }
    }
    const fontWeight = props.fontWeight ? props.fontWeight : "bold";
    const marginTop = props.marginTop === undefined ? 50 : props.marginTop;
    const marginBottom = props.marginBottom === undefined ? 50 : props.marginBottom;
    const align = props.align ? props.align : "center";
    const shadow = `6px 6px ${props.shadowBlur === undefined ? "6" : "2"}px ${props.shadowColor ? props.shadowColor : app.themes.textShadowRed}`;
    return (
        <StyledHeaderText fontSize={fontSize} fontWeight={fontWeight} marginTop={marginTop} marginBottom={marginBottom} align={align} shadow={shadow}
            dangerouslySetInnerHTML={{ __html: props.text }} />
    )
}

export default Button;

/*
        <Container>
        <ButtonContainer backColor={props.darkBackground ? app.themes.backShadeColor : "transparent"} onClick={props.onClick}>
            {props.caption}
        </ButtonContainer>
        </Container>
*/