import React from 'react';
import styled from 'styled-components';

import { formatImageUrl } from '../libs/ImageFormatter';
import MasterPage from './MasterPage';
import { HeaderText } from './KarinaCommon';

import app from '../appData';

const taiChiLogoFilename = "tai-chi-logo-gold.png";

const subheaderText1 = `
Breath-Sound &bull; Movement &bull; Chi Gung<br />
Awareness & Intention &bull; Standing & Walking Meditation<br />
Balance &bull; Centering & Grounding`
const subheaderText2 = `
The Principles of T'ai Chi & The Art of Slowing Down<br />
Mondays 6 - 7 PM<br />
For Information and Registration, call: (510) 559-8892<br />
Private Sessions Available<br />`

const taichiImages = [
    formatImageUrl("karina-clapping.jpg"),
    formatImageUrl("tai-chi-statue-medium.jpg"),
    formatImageUrl("tai-chi-statue-close.jpg")
];

const taichiText = `
<p><img style="width: 250px; height: auto; margin-right: 12px; float: left;" src=${taichiImages[0]} />Karina brings to her 30 years of teaching and extensive background and training in T&apos;ai Chi Chu&apos;an, dance, physical theater, music, voice and art. 
She has coached many artists, unleashing creative potential by exploring the &quot;hidden.&quot; Her teaching focuses on listening deeply and cultivating awareness. 
Past workshops include: Finding Our Voices and River of Breath, her Chi Flow class at the Claremont Spa, and 6 years of Creative Expression at drug rehab 
facilities and a federal women&apos;s prison. Her workshop Walking in Balance is an outdoor practice. Karina is featured in Stephanie Hoppe&apos;s 
&quot;Sharp Spear, Crystal Mirror: Martial Arts in Women&apos;s Lives.&quot;</p>
<p><img style="width: 250px; height: auto; margin-left: 12px; float: right;" src=${taichiImages[1]} />For almost thirty years, I have allowed the practice of T&apos;ai Chi Ch&apos;uan to work on me and through me. As it merged over the years with other skills and interests of mine, 
I increasingly saw myself teaching from the perspective of a woman born into Western culture. For me, T&apos;ai Chi as the “mother of all” is a musical sphere that embraces the 
melodies of yin and yang, the pulse of full and empty, the breath of in and out. When this ancient philosophy is experienced in the body along with gentle awareness, 
a song arises, we remember, and new pathways open, as well as forgotten possibilities. We dare to unlearn. 
The laws of our human existence come into focus: gravity & alignment, growing roots & playful balance, resonance & harmony, inter-relatedness & attunement. 
T&apos;ai Chi becomes a life long discovery: teaching us humility, flexibility and perseverance, providing us with an honest mirror and a structure for self-cultivation. 
I love that inner strength is emphasized over muscle power: &quot;Softness overcomes hardness,&quot; say the classics, and urge us to learn to &quot;invest in loss.&quot;
<p><img style="width: 250px; height: auto; margin-right: 12px; float: left;" src=${taichiImages[2]} />T&apos;ai Chi Chu&apos;an is an ancient practice, full of paradoxes and wisdom, reminding us of our sacred humble part in Nature and Spirit. As we practice slowing down, 
the sacredness of our breath, movement and everyday life arises before us, comforting our overloaded bodies and minds with ease, addressing our longing for connection, 
spaciousness, balance and serenity.`

interface QuoteRecord {
    text: string;
    location: { top: number; left: number };    // top is px, left is %
    attribution: string;
    break?: boolean;
}
const quotes: QuoteRecord[] = [
    {
        location: { top: 0, left: 0 },
        attribution: "Kathryn Bing-You, Feng Shui Consultant",
        text: `Monday’s class is sacred to me, which means I go out of my way not to schedule anything else that evening. 
        I especially enjoy the sounding/toning during the first hour. It’s a great way to relieve the tensions/stresses of the day, 
        and brings me back to myself, my body. Both you and the group make it safe to explore with sound, and breathing.`
    },
    {
        location: { top: 32, left: 10 },
        attribution: "Louise Barrie, Huma Somatic Psychotherapy",
        text: `Karina's T’ai chi class is a haven for me. She teaches it from the inside - out. It is a remembering, again and again the center. 
        She teaches very much from what is happening “now”, and I often feel inspired by her spontaneous creativity.`,
        break: true
    },
    {
        location: { top: 8, left: 30 },
        attribution: "John Knoop, filmmaker",
        text: `Focusing on T’ai Chi with Karina has given stability to my life and provided a framework for my acceptance of disability. 
        The group is like another very supportive family that I look forward to visiting every week.`,
        break: true
    },
    {
        location: { top: 16, left: 5 },
        attribution: "Geri Stern, Security Pacific Real Estate",
        text: `Karina is especially well versed in movement arts and takes her students on an introspective journey in each class. She is truly an amazing and loving 
        guide who is happy to assist her students with their own particular physical concerns. Just being in Karina's presence alone, gives me a feeling of confidence 
        and comfort. She has a unique quality of being able to bring the group together so that all participants feel the collective energy and the benefits of the powerful 
        oneness that only occurs in a group setting.`
    },
    {
        location: { top: 60, left: 7 },
        attribution: "Meredith Kaplan, landscape architect, gardener",
        text: `The work you do is magical. Perhaps "mystical" is the word – you see us so completely and bring us along at our own speed. I am in awe of your sensitivity to 
        each individual. For me, you are a spiritual guide. You have shown me a way to seek spirituality without tying it to a traditional religious experience but to my body 
        and the world soul. I came to appreciate your insistence on our being rooted, your attention to detail. Working with you has also made me aware of needing to find my 
        own voice. The breath work has been amazing.`,
        break: true
    },
    {
        location: { top: 18, left: 0 },
        attribution: "Jane Hysen, physician assistant",
        text: `Your class has been probably the most important activity I have had the opportunity to be involved with in my recovery. 
        You teach lovingly without pressure to quickly learn a lot of new movements. I can never thank you enough for your help. 
        I do consider it one of the fortunes of my stroke.`,
        break: true
    },
    {
        location: { top: 72, left: 4 },
        attribution: "Catherine Hartshorn, PhD, therapist",
        text: `Karina’s class is an essential part of my life, and I hope to grow old with it. Her wisdom and gentleness combined with an exacting and high standard is always 
        a gift to be around. Her little impromptu lectures are really a philosophy of life, and her grace when moving and expressing is an inspiration.`
    },
    {
        location: { top: -90, left: 5 },
        attribution: "Alison Odell, writer, educator, musician & artist",
        text: `Karina, you confront us with truth and acceptance, honoring that each student has a particular path and pace. You model what it means to have high 
        expectations of oneself, and to be honest, self-accepting and humble at the same time. Thanks for your caring, your guidance, and the sense of humor you bring 
        to your work!`,
        break: true
    },
    {
        location: { top: 40, left: 28 },
        attribution: "Karen Fox-Reynolds, school teacher",
        text: `Every class is an experience that goes deep and stays with me through my week and beyond. I would call you a “guide”.  You do it with skill, directness, 
        and grace. Thank you for all you have done for me in my life.`
    }
];

const MasterContainer = styled.div`
    padding-bottom: 60px;    
    margin-top: 32px;
    h2 {
        font-size: 28px;
        font-weight: normal;
        text-align: center;
    }
`
const LogoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
`
const TaiChiLogoContainer = styled.div`
    width: 24%;
`
const TaiChiLogo = styled.img`
    width: 100%;
    height: auto;
`
const TextBox = styled.p<{ marginTop: number }>`
    text-align: center;
    width: 38%;
    margin-top: ${props => props.marginTop}px;
`
const SubheaderText = styled.p`
    font-size: 28px;
    font-weight: normal;
    line-height: 44px;
    text-align: center;
    margin-top: 32px;
`
const TaiChiText = styled.div`
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    background-color: ${app.themes.backShadeColor};
    padding: 24px 40px 24px 40px;
`
const BottomLogoContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 350px;
`
const BottomLogo = styled.img`
    width: 100%;
    height: auto;
`
const PullQuote = styled.p`
    font-size: 60px;
    font-style: italic;
    font-weight: normal;
    text-align: center;
`

const MindBody: React.FC = (props) => {
    const quotesMap: (number[])[] = [
        [0, 1], [2], [3, 4], [5], [6, 7], [8]
    ];
    return (
        <MasterPage>
            <MasterContainer>
                <HeaderText marginBottom={20} text="The Art of Slowing Down" />
                <h2>T'ai Chi Ch'uan<br />Breath</h2>
                <LogoContainer>
                    <TextBox marginTop={78}>
                        &quot;Karina is a wonderful teacher-
                        her style is highly personal
                        and yet always within her vast
                        perspective on body wisdom.&quot;<br />
                        &mdash;&nbsp;<span style={{ fontStyle: "italic" }}>Ruth Zaporah, performance artist and teacher</span>
                    </TextBox>
                    <TaiChiLogoContainer>
                        <TaiChiLogo src={formatImageUrl(taiChiLogoFilename)} />
                    </TaiChiLogoContainer>
                    <TextBox marginTop={78}>
                        &quot;I am highly appreciative of the warm,
                        intuitive and challenging ways in which
                        Karina encourages awareness that leads
                        new, surprising and exciting expressions of self.&quot;<br />
                        &mdash;&nbsp;<span style={{ fontStyle: "italic" }}>Carl Eggers, psychotherapist & couples counselor</span>
                    </TextBox>
                </LogoContainer>
                <HeaderText customFontSize={24} marginTop={24} marginBottom={12} text="with" />
                <HeaderText customFontSize={40} marginTop={0} text="Karina Epperlein" />
                <SubheaderText dangerouslySetInnerHTML={{ __html: subheaderText1 }} />
                <SubheaderText dangerouslySetInnerHTML={{ __html: subheaderText2 }} />
                <TaiChiText dangerouslySetInnerHTML={{ __html: taichiText }} />
                <BottomLogoContainer>
                    <BottomLogo src={formatImageUrl(taiChiLogoFilename)} />
                </BottomLogoContainer>
                <PullQuote>
                    be still as a mountain, move like a great river
                </PullQuote>
                {quotesMap.map(indexes => {
                    return (
                        indexes.length == 2 ? (
                            <QuoteRow key={indexes[0]}>
                                <QuoteRenderer quote={quotes[indexes[0]]} />
                                <QuoteRenderer quote={quotes[indexes[1]]} />
                            </QuoteRow>
                        ) : (
                            <QuoteRenderer key={indexes[0]} quote={quotes[indexes[0]]} />
                        )
                    )
                })}
            </MasterContainer>
        </MasterPage>
    )
}
const QuoteRow = styled.div`
    display: flex;
`
const QuoteBox = styled.div<{ left: number; top: number }>`
width: 40%;
margin-left: ${props => props.left}%;
    margin-top: ${props => props.top}px;
    p {
        text-align: center;
        background-color: ${app.themes.backShadeColor};
        padding: 16px;
        line-height: 32px;
    }
`
const AttributionText = styled.span`
    font-style: italic;
`
interface QuoteRendererProps {
    quote: QuoteRecord;
}
const QuoteRenderer: React.FC<QuoteRendererProps> = (props) => {
    return (
        <QuoteBox left={props.quote.location.left} top={props.quote.location.top}>
            <p>
                &quot;<span dangerouslySetInnerHTML={{ __html: props.quote.text }}></span>&quot;<br />
                <AttributionText>&mdash;&nbsp;{props.quote.attribution}</AttributionText>
            </p>
        </QuoteBox>
    )
}
export default MindBody;