import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

import MasterPage from '../MasterPage';
import { HeaderFontSize, HeaderText } from '../KarinaCommon';
import { formatTextForHtml } from '../../libs/libSupport';
import IconButton, { ButtonsRow } from '../../libs/IconButtonV2';

import app from '../../appData';
import StickyNavBar from '../../libs/StickyNavBar';
import { IconButtonAlignEnum } from '../../interfaces/lib-react-interfaces';
import { formatImageUrl } from '../../libs/ImageFormatter';

export const defaultPadding = 24;

interface FilmCardRecord {
    left?: number;      // left OR align must be valid; left is %
    align?: string;
    title: string;
    shortTitle?: string;        // default to title
    filmBy?: string;            // default to Karina
    image: string;
    text: string;       // one paragraph, html
    specialText?: string;   // between text and blurb, bold and in gold
    blurbs?: BlurbRecord[];
    url: string;        // "more" button link
    imageWidthPct?: number;     // defaults to 30
    widthPct?: number;      // defaults to 50
}
interface BlurbRecord {
    text: string;
    attribution?: string;
}
const whyText = `&ldquo;Most important to me is to listen beneath the surfaces of images and facts, allowing the deeper truth of a
person or situation come to light. I make films about the forces that come through people – the spirit or universal story that wants to be known.
With my films I am inviting my audiences into the realm of poetry and listening, a state of mind that counterbalances the busy world of media.
I invite them to see with the inner eye. As a storyteller I spin and weave layer upon layer, allowing viewers to join with their own imaginations
weaving and spinning still long after the film is over.&rdquo;&nbsp;&mdash;&nbsp;<span style="font-style: italic;">Karina Epperlein</span>`

const cards: FilmCardRecord[] = [
    {
        align: "left",
        title: "Finding The Gold Within",
        shortTitle: "Finding The Gold Within",
        image: "gold-postcard.jpg",
        text: `Six young African American men from Akron, Ohio, enter college, having to navigate persistent racial provocations. Alumni of the unique Akron, Ohio, 
        mentoring program Alchemy, they share their trials and triumphs with honesty, astonishing vulnerability, and raw emotion.`,
        blurbs: [{
            text: `Inspiring, heartbreaking, and unflinchingly honest, this film is not just about the journeys these men must take, but the journeys we all 
        must share to bring about change.`,
            attribution: "Clint Rohrbacher, Cleveland International Film Festival"
        }],
        url: "http://goldthefilm.com",
        widthPct: 60
    },
    {
        left: 20,
        title: "Phoenix Dance",
        image: "phoenix-card-redo.jpg",
        text: `About a one-legged dancer’s return to the stage, featuring Homer Avila, Andrea Flores, and renown choreographer Alonzo King from LINES Ballet.</p>`,
        specialText: `&ldquo;Short listed&rdquo; for 2006 Oscar nomination`,
        blurbs: [{
            text: "Deeply inspirational…",
            attribution: "The New York Times"
        },
        {
            text: "Elegant...",
            attribution: "The Village Voice"
        }],
        url: "/phoenix-dance",
        widthPct: 53,
        imageWidthPct: 40
    },
    {
        left: 40,
        title: "Voices from Inside",
        image: "voices-from-inside-postcard.jpg",
        text: `Inside a federal prison, poetry and creative expression help a racially mixed circle of women find their voices. 
        The women prisoners and their children on the outside speak out.`,
        blurbs: [{
            text: "I found it beautifully made and very moving.  It is so important for us on the outside to realize what 'treasures' are hidden away on the inside.",
            attribution: "Susan Sarandon"
        }],
        url: "/voices",
        widthPct: 48,
        imageWidthPct: 40
    },
    {
        align: "left",
        title: "I Will Not Be Sad In This World",
        shortTitle: "I Will Not Be Sad In This World",
        image: "not-sad-postcard.jpg",
        text: `Intimate portrait of a 94-year-old Armenian woman – who survived the genocide of her people in 1915 &mdash; 
        tending to her garden in Fresno, with her two sons, granddaughter and grandchild visiting, trying to elicit memories from her.`,
        blurbs: [{
            text: "A touching and evocative film – having the children recreate Zaroohe’s story is particularly effective. Congratulations!",
            attribution: "Atom Egoyan, filmmaker"
        }],
        url: "/sad",
        widthPct: 55
    },
    {
        left: 10,
        title: "Awakening from Sorrow:<br />Buenos Aires, 1997",
        shortTitle: "Awakening from Sorrow",
        filmBy: "John Knoop and Karina Epperlein",
        image: "awakening-postcard.jpg",
        text: `Documentary about a crucial moment in history when the sorrow of young Argentines – whose parents were disappeared, tortured and killed during the 'Dirty War' &mdash; 
        is erupting into public action, and becomes a cornerstone for people’s movements all over South America.`,
        url: "/awakening",
        imageWidthPct: 40,
        widthPct: 60
    },
    {
        left: 20,
        title: "In the Shadow of Huma's Wing",
        image: "huma-poster.jpg",
        text: `This highly intimate portray of Louise Barrie&apos;s Huma Somatic Psychotherapy takes us on a journey of experiencing, not just observing this unique work. 
        Karina&apos;s sensitive, tightly framed camera and the flicker of dark and light of the abstract black and white super 8 footage keep the viewer on his/her inner 
        journey towards the deeper self. We witness the course of four (collapsed) private sessions, interspersed with Louise highlighting the main points of her work, 
        as well seeing her coaching advanced practitioners in one of her workshops`,
        url: "/huma",
        imageWidthPct: 40,
        widthPct: 65
    }
];

const MasterContainer = styled.div`
    text-align: center;
    padding-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
    line-height: 28px;
`
const SubheaderText = styled.p`
    text-align: center;
    font-weight: normal;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 48px;
`
interface FilmsMasterPageProps extends React.PropsWithChildren<{}> {
    tightSpacing?: boolean;
}
export const FilmsMasterPage: React.FC<FilmsMasterPageProps> = (props) => {
    return (
        <MasterContainer>
            <HeaderText marginBottom={props.tightSpacing ? 20 : 50} text="karinafilms presents" />
            <SubheaderText>insipirational films that take the viewer on an inner journey</SubheaderText>
            {props.children}
        </MasterContainer>
    )
}
//------------------------------------------------------

const CardContainer = styled.div<{ widthPct: number; marginLeft: string; marginRight: string }>`
    display: flex;
    padding: 24px;
    width: ${props => props.widthPct}%;
    text-align: left;
    margin: 32px ${props => props.marginRight} 0 ${props => props.marginLeft};
    background-color: ${app.themes.backShadeColor};
`
const CardText = styled.p`
    margin-top: 0;
    margin-bottom: 8px;
    margin-left: 12px;
`
const BlurbText = styled(CardText)`
    color: ${app.themes.goldColor};
`
const SpecialText = styled(CardText)`
    font-weight: bold;
    text-shadow: 2px 2px 2px red;
`
const AttributionText = styled.span`
    font-style: italic;
`
const FilmByText = styled.p`
    font-size: 16px;
    text-align: center;
    margin-top: 8px;
`
const ImageDiv = styled.div<{ width: number }>`
    min-width: ${props => props.width}%;
`
const Image = styled.img`
    width: 100%;
    height: auto;
    cursor: pointer;
`
const TextWithButton = styled.div`
    display: flex;
    flex-direction: column;
`
const KarinaFilms: React.FC = () => {
    const [stickyMenu, setStickyMenu] = React.useState<string[]>();
    const navigate = useNavigate();
    
    React.useEffect(() => {
        const menu: string[] = [];
        for (const item of cards) {
            if (!item.title.startsWith("why")) {
                menu.push(item.shortTitle ? item.shortTitle : item.title);
            }
        }
        setStickyMenu(menu);
    }, []);

    const cardClicked = (url?: string) => {
        if (url) {
           navigate(url);
        }
    }

    return (
        <MasterPage>
            <FilmsMasterPage>
                <CardContainer widthPct={60} marginLeft="auto" marginRight="auto">
                    <TextWithButton id="why">
                        <HeaderText marginTop={0} fontSize={HeaderFontSize.medium} fontWeight="normal" text="why i make films" />
                        <CardText dangerouslySetInnerHTML={{ __html: whyText }} />
                    </TextWithButton>
                </CardContainer>
                {stickyMenu &&
                    <StickyNavBar id="karina-films-nav" maxWidth={1000} marginTop={36} menu={stickyMenu} darkBackColor={app.themes.stickyMenuBackColor} />
                }
                {cards.map(card => {
                    let marginLeft: string;
                    let marginRight: string;
                    if (card.left) {
                        marginLeft = card.left + "%";
                        marginRight = "0";
                    } else if (card.align === "left") {
                        marginLeft = marginRight = "0";
                    } else {
                        marginLeft = marginRight = "auto";
                    }
                    return (
                        <CardContainer widthPct={card.widthPct ? card.widthPct : 50} marginLeft={marginLeft} marginRight={marginRight}>
                            <ImageDiv width={card.imageWidthPct ? card.imageWidthPct : 30}>
                                <Image src={formatImageUrl(card.image, { graphicsSubfolder: "films" })} onClick={() => cardClicked(card.url)} />
                            </ImageDiv>
                            <TextWithButton id={formatTextForHtml(card.shortTitle ? card.shortTitle : card.title)}>
                                {card.url.startsWith("http") ? (
                                    <a href={card.url} target="_blank" rel="noopener noreferrer">
                                        <HeaderText marginTop={0} marginBottom={4} fontSize={HeaderFontSize.medium} fontWeight="normal" text={card.title} />
                                        <FilmByText>A film by {card.filmBy ? card.filmBy : "Karina Epperlein"}</FilmByText>
                                    </a>
                                ) : (
                                    <Link to={card.url!}>
                                        <HeaderText marginTop={0} marginBottom={4} fontSize={HeaderFontSize.medium} fontWeight="normal" text={card.title} />
                                        <FilmByText>A film by {card.filmBy ? card.filmBy : "Karina Epperlein"}</FilmByText>
                                    </Link>
                                )}
                                <CardText dangerouslySetInnerHTML={{ __html: card.text }} />
                                {card.specialText &&
                                    <SpecialText dangerouslySetInnerHTML={{ __html: card.specialText }} />
                                }
                                {card.blurbs &&
                                    <React.Fragment>
                                        {card.blurbs.map(blurb => {
                                            return (
                                                <BlurbText key={blurb.text}>
                                                    &quot;{blurb.text}&quot;&nbsp;&mdash;&nbsp;<AttributionText>{blurb.attribution}</AttributionText>
                                                </BlurbText>
                                            )
                                        })}
                                    </React.Fragment>
                                }
                                <ButtonsRow align={IconButtonAlignEnum.right}>
                                    <IconButton caption="More..."
                                        onClick={() => { card.url.startsWith("http") ? window.open(card.url, '_blank', 'noopener') : navigate(card.url!) }} />
                                </ButtonsRow>
                            </TextWithButton>
                        </CardContainer>
                    )
                })}
            </FilmsMasterPage>
        </MasterPage >
    )
}
export default KarinaFilms;
