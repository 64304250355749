import React from 'react';
import styled from 'styled-components';
import { Link, Navigate, useLocation } from 'react-router-dom'

import NavBar from '../libs/NavBarV4';
import { menuItems } from '../Routes';
import { useCookies } from '../libs/SamState';

import app from '../appData';
import { MenuItemData } from '../interfaces/lib-api-interfaces';

const MasterContainer = styled.div`
    font-family: ${app.themes.defaultFonts};
    color: ${app.themes.backColor10};
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    line-height: 34px;
    a:link {
        text-decoration: none;
        color: inherit;
    }
    a:visited {
        text-decoration: none;
        color: inherit;
    }
    a:hover {
        text-shadow: 2px 2px 2px red;
        font-style: italic;
    }

`
const LogoAndNav = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 20px;

`
const LogoImage = styled.img`
    width: 420px;
    height: auto;
    margin-top: 16px;
    margin-left: 24px;
`
const NavBarContainer = styled.div`
    width: 500px;
`
const MasterPage: React.FC<React.PropsWithChildren<{}>> = (props) => {
    const location = useLocation();
    const { getCookie } = useCookies();

    if (window.location.hostname !== "localhost" && !getCookie("beta")) {
        return (
            <Navigate to="/beta-login" />
        )
    }
    const color = "#fb3148";
    return (
        <MasterContainer>
            <LogoAndNav>
                <Link to="/home">
                    <LogoImage src="/graphics/karinaland-gold-glow.png" />
                </Link>
                <NavBarContainer>
                    <NavBar menuItems={menuItems}
                        mainOptions={{
                            fontSize: 24, fontWeight: "normal", backColor: "transparent", foreColor: app.themes.reverseForeColor, hoverForeColor: app.themes.goldColor,
                            hoverBackColor: "transparent", padding: 32
                        }} />
                </NavBarContainer>
            </LogoAndNav>
            {props.children}
            <Footer />
        </MasterContainer>
    );
}

const FooterMasterContainer = styled.div`
    width: 100%;
    max-width: 1600px;
    margin: 32px auto 32px auto;
    padding-bottom: 16px;
    background-color: ${app.themes.backShadeColor};
`
const FooterContainer = styled.div`
    display: flex;    
    justify-content: space-around;
    width: 100%;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
`
const FooterListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 8px;
    padding-right: 8px;
    p {
        font-size: 16px;
        line-height: 24px;
        margin: 0;
    }
    h3 {
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 8px;
    }
`
const FooterMenu = styled.div`
    p:hover {
        text-shadow: 2px 2px 2px red;
        font-style: italic;
    }
`
const FooterNavItem = styled.p`
    cursor: pointer;
`
const Footer: React.FC = () => {
    return (
        <FooterMasterContainer>
            <FooterContainer>
                <VerticalMenu title="Navigate" menu={menuItems} />
                <FooterListContainer>
                    <h3>Contact Karina</h3>
                    <p>(510) 559-8892</p>
                    <p><a href="mailto:karina@karinafilms.us">karina@karinafilms.us</a></p>
                </FooterListContainer>
                <FooterListContainer>
                    <h3>Connect With Karina</h3>
                    <SocialMedia />
                </FooterListContainer>
            </FooterContainer>
        </FooterMasterContainer>
    )
}
interface VerticalMenuProps {
    title: string;
    menu: MenuItemData[];
}
const VerticalMenu: React.FC<VerticalMenuProps> = (props) => {
    const handleNavClick = (href?: string) => {
        if (href) {
            if (href.startsWith("http")) {
                window.open(href, '_blank');
                // window.location.href = href;
            } else {
                window.location.href = href;
            }
        }
    }

    return (
        <FooterListContainer>
            <FooterMenu>
                <h3>{props.title}</h3>
                {props.menu.map(item => {
                    let href = item.href;
                    if (!href && item.popupItems) {
                        href = item.popupItems[0].href;
                    }
                    return (
                        <FooterNavItem key={item.caption} onClick={() => handleNavClick(item.href)}>{item.caption}</FooterNavItem>
                    )
                })}
            </FooterMenu>
        </FooterListContainer>
    )
}
const SocialMediaGroup = styled.div<{ justify: string; backColor: string; height?: number }>`
    display: flex;
    justify-content: ${props => props.justify};
    align-items: center;
    background-color: ${props => props.backColor};
    height: ${props => props.height}px;
`
const SocialMediaItem = styled.div`
    margin-right: 12px;
    font-size: 24px;
`
interface SocialMediaProps {
    justify?: string;        // flex-start or center; default to flex-start
    backColor?: string;     // default to transparent
    height?: number;
}
export const SocialMedia: React.FC<SocialMediaProps> = (props) => {
    return (
        <SocialMediaGroup justify={props.justify ? props.justify : "flex-start"} backColor={props.backColor ? props.backColor : "transparent"} height={props.height}>
            <SocialMediaItem>
                <a href={''} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square" /></a>
            </SocialMediaItem>
            <SocialMediaItem>
                <a href={''} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram-square" /></a>
            </SocialMediaItem>
        </SocialMediaGroup>
    )
}

export default MasterPage;

/* <LogoImage src="/graphics/karinaland-logo-300.png" alt="KarinaLand" />
                <LogoImage src="/graphics/logo-gold-medium.png" />
                <LogoImage src="/graphics/logo-gold-light.png" /> */

