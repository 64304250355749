import React from 'react'
import styled from 'styled-components';

import { SliderRecord } from '../interfaces/lib-react-interfaces';
import { iPhoneWidth } from './libSupport';

const isTesting = false;

const MasterContainer = styled.div<{ height: number }>`
    position: relative;
    height: ${props => props.height}px;
    width: 100%;
`
const Images = styled.div`
    display: ${isTesting ? "flex" : "block"};
`
const ImageContainer = styled.div<{ alignLeft?: boolean }>`
    display: flex;
    justify-content: ${props => props.alignLeft ? "flex-start" : "center"};
    position: ${isTesting ? "relative" : "absolute"};
    width: 100%;
`
const StyledImage = styled.img<{ maxHeight: number; maxWidth: number; fadeTimeMs: number; isFadingIn: boolean }>`
    max-height: ${props => props.maxHeight}px;
    max-width: ${props => props.maxWidth}px;
    z-index: 4;
    width: auto;
    height: auto;
    position: ${isTesting ? "relative" : "absolute"};
    z-index: ${props => props.isFadingIn ? "9" : "10"};
    opacity: 0;
    animation: "fadeInOut" ease ${props => props.fadeTimeMs}ms;
    animation-direction: normal, reverse;
    @keyframes fadeInOut {
        0%,100% { opacity: 0; }
        40%,60% { opacity: 1; }
      }
 }
`
/*
opacity: ${props => props.isFadingIn ? "0" : "1"};
animation: ${props => props.isFadingIn ? "fadeInOut" : "fadeOutIn"} ease ${props => props.fadeTimeMs}ms;
@keyframes fadeOut {
    0% {opacity: 1};
    100% {opacity: 0};
}
@keyframes fadeIn {
    0% {opacity: 0};
    100% {opacity: 1};
}
@keyframes fadeOutIn {
    0%,100% { opacity: 1; }
    50% { opacity: 0; }
  }
*/

// NOTE: max width must be hard coded in StyledImage and style for animated.div
interface SliderProps {
    images: SliderRecord[];
    delay: number;
    alignLeft?: boolean;
    border?: string;
    height?: number;        // default to 400px
    onClick?: (userData: string) => void;       // not called if userData is null/undefined
}
const Slider: React.FC<SliderProps> = (props) => {
    const [index1, setIndex1] = React.useState<number>();
    const [index2, setIndex2] = React.useState<number>();
    const [maxSize, setMaxSize] = React.useState<{ width: number; height: number }>({ width: 0, height: 0 });
    //    const [fadeInHeight, setFadeInHeight] = React.useState(height);

    const containerRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    const nextIndex = (allImages: SliderRecord[], currIndex: number) => {
        return ((currIndex + 1) % allImages.length);
    }

    React.useEffect(() => {
        if (window.matchMedia("(max-width: " + iPhoneWidth + "px)").matches) {
            console.log("small device: max-height=" + window.innerHeight)
            setMaxSize({ width: window.innerWidth, height: window.innerHeight });
            window.scrollTo(0, containerRef.current.getBoundingClientRect().top);
        } else {
            setMaxSize({ width: window.innerWidth, height: props.height ?? 400 });
        }

        // const newImages = [] as SliderRecord[];
        // props.images.forEach((image, index) => {
        //     newImages.push({ ...image, id: index });
        // });
        // setImages(newImages);
        //       let intervalId: NodeJS.Timer;
        setIndex1(0);
        if (props.delay) {
            setTimeout(() => {
                setIndex2(1);
            }, props.delay / 2);

            // intervalId = setInterval(() => {
            //     setIndex(state => {
            //         const next = nextIndex(newImages, state);
            //         return next;
            //         // return nextIndex(newImages, next);
            //     });
            //    }, props.delay);
        }
        // return (() => {
        //     //      console.log("clearing interval")
        //     intervalId && clearInterval(intervalId);
        // });
    }, []);

    const animationEnded = (e: React.AnimationEvent) => {
        const target = e.target as HTMLImageElement;
        if (target.id === '1') {
            setIndex1(state => {
                const next = nextIndex(props.images, state!);
                return nextIndex(props.images, next);
            });
        } else {
            setIndex2(state => {
                const next = nextIndex(props.images, state!);
                return nextIndex(props.images, next);
            });
        }
    }
    /*
    React.useEffect(() => {
        setTimeout(() => {
            setIndex1(state => {
                const next = nextIndex(props.images, state!);
                return nextIndex(props.images, next);
            });
        }, props.delay);
    }, [index1]);
    React.useEffect(() => {
        setTimeout(() => {
            setIndex2(state => {
                const next = nextIndex(props.images, state!);
                return nextIndex(props.images, next);
            });
        }, props.delay);
    }, [index2]);
    */

    const imageClicked = (e: React.MouseEvent<HTMLImageElement>) => {
        // const idx = nextIndex(images!, index2);
        // if (props.onClick && images![idx].userData) {
        //     props.onClick(images![idx].userData!);
        // }
    }
    /*
    const imageLoaded = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const target = e.target as HTMLImageElement;
        let factor = height / target.naturalHeight;
        const width = target.naturalWidth * factor;
        let imageHeight = height;
        if (width > window.innerWidth) {
            factor = window.innerWidth / target.naturalWidth;
            imageHeight = factor * target.naturalHeight;
        }
        if (imageHeight !== fadeInHeight) {
            setFadeInHeight(imageHeight);
        }
        // const rect = target.getBoundingClientRect();
        // if (rect.height !== imageHeight) {
        //   //  console.log("setting height to " + rect.height)
        //     setImageHeight(rect.height);
        // }
    }
    */
    const border = props.border;        // not used currently
    // console.log({ index, src: images ? images[index].src : undefined });
    // note: while using images.map seems unnecessary, animation will not work without this
    return (
        <MasterContainer ref={containerRef} height={maxSize.height}>
            {props.images.map((image, idx) => {
                return (
                    <Images>
                        {idx === index1 &&
                            <ImageContainer alignLeft={props.alignLeft}>
                                <StyledImage id="1" src={props.images[index1].src} fadeTimeMs={props.delay} isFadingIn={true}
                                    maxWidth={maxSize.width} maxHeight={maxSize.height} 
                                    onAnimationEnd={animationEnded} />
                            </ImageContainer>
                        }
                        {idx === index2 &&
                            <ImageContainer alignLeft={props.alignLeft}>
                                <StyledImage id="2" src={props.images[index2].src} fadeTimeMs={props.delay} isFadingIn={true}
                                    maxWidth={maxSize.width} maxHeight={maxSize.height}
                                    onClick={imageClicked} onAnimationEnd={animationEnded} />
                            </ImageContainer>
                        }
                    </Images>
                )
            })
            }
        </MasterContainer>
    );
}

export default Slider;

// style={{display: "flex"}}