import React from 'react';

import { usePostApi, genericApiError } from './useDataApiV2';
import { useCookies } from './SamState';

import app from '../appData';
import { Navigate } from 'react-router-dom';

const BetaLogin: React.FC = () => {
    const { post } = usePostApi();
    const { getCookie, setCookie } = useCookies();

    const postSuccessful = () => {
        setCookie("beta", true, 60);
        window.location.href = "/";
    }
    const keyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            post("/api/betaLogin", { domain: app.targetDomain, password: ((e.target) as HTMLInputElement).value }, postSuccessful, () => alert(genericApiError));
        }
    }
    if (getCookie("beta")) {
        return (
            <Navigate to="/home" />
        )
    }
    return (
        <input placeholder="Enter password" onKeyPress={keyPressed} />
    )
}
export default BetaLogin;