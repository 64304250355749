import React from 'react';
import styled from 'styled-components';
import scrollIntoView from 'scroll-into-view';

import { formatTextForHtml } from './libSupport';
import IconButton, { ButtonsRow } from './IconButtonV2';
import { IconButtonAlignEnum } from '../interfaces/lib-react-interfaces';

const StickyNavContainer = styled.div<{ maxWidth?: number; backColor: string; marginTop: number }>`
    max-width: ${props => props.maxWidth ? props.maxWidth + "px" : "100%"};
    background-color: ${props => props.backColor};
    margin-top: ${props => props.marginTop};
    padding: 16px;
    position: sticky;
    top: 10px;
`
// if triggerElementId not defined, the menu is always shown; when sticking to top its background color turns black
// if triggerElementId is defined, the menu shows only when this element scroll above window
interface StickyNavBarProps {
    id: string;
    menu: string[];         // list of captions; actual element id's must be formatted from these using formatTextForHtml
    height?: number;        // default to 50px
    maxWidth?: number;
    marginTop?: number;
    triggerElementId?: string; 
    normalBackColor?: string;   // required if triggerElementId not given
    darkBackColor?: string;     // set when bar becomes fixed
    buttonStyles?: Record<string, string>;      // default to app.themes
    hoverStyles?: Record<string, string>;       // ditto
}
const StickyNavBar: React.FC<StickyNavBarProps> = (props) => {
    const [isNavDark, setIsNavDark] = React.useState(false);
    const [navTop, setNavTop] = React.useState(0);
    const [triggerTop, setTriggerTop] = React.useState(0);
    const [showNav, setShowNav] = React.useState(false);            // used only if trigger element given

    const height = props.height ?? 50;

    React.useEffect(() => {
        if (props.triggerElementId) {
            // see if this element has scrolled above window
            const elem = document.getElementById(props.triggerElementId);
            setTriggerTop(elem!.getBoundingClientRect().top);
            document.addEventListener('scroll', function (e) {
                const elem = document.getElementById(props.triggerElementId!);
                if (elem) {
                    const rect = elem.getBoundingClientRect();
                    if (triggerTop !== rect.top) {
                        setTriggerTop(rect.top);
                    }
                }
            });
        } else {
            const elem = document.getElementById(props.id);
            setNavTop(elem!.getBoundingClientRect().top);
            document.addEventListener('scroll', function (e) {
                const elem = document.getElementById(props.id);
                if (elem) {
                    const rect = elem.getBoundingClientRect();
                    if (navTop !== rect.top) {
                        setNavTop(rect.top);
                    }
                }
            });
        }
    }, []);

    React.useEffect(() => {
        if (props.triggerElementId) {
            if (triggerTop < 100 && !showNav) {
                setShowNav(true);
            }
            if (triggerTop >= 100 && showNav) {
                setShowNav(false);
            }
        } else {
            if (navTop === 10 && !isNavDark) {
                setIsNavDark(true);
            }
            if (navTop !== 10 && isNavDark) {
                setIsNavDark(false);
            }
        }
    }, [navTop, triggerTop]);

    const stickyNavClicked = (item: string) => {
        const id = formatTextForHtml(item);
        const elem = document.getElementById(id) as HTMLDivElement;
        //   elem.style.minHeight = "90px";
        if (elem) {
            scrollIntoView(elem, { time: 1000, align: { topOffset: 100, top: 0, lockX: true } });
        }
    }
    const darkBackColor = props.darkBackColor ?? "transparent";

    if (!showNav && props.triggerElementId) {
        return null;
    }
    return (
        <StickyNavContainer id={props.id} maxWidth={props.maxWidth} backColor={isNavDark ? darkBackColor : "transparent"} marginTop={props.marginTop ?? 0}>
            <ButtonsRow height={height} marginTop={0} align={IconButtonAlignEnum.spaceBetween} marginBottom={0}>
                {props.menu.map(item => {
                    return (
                        <IconButton key={item} caption={item} onClick={() => stickyNavClicked(item)} />
                    )
                })}
            </ButtonsRow>
        </StickyNavContainer>
    )
}
export default StickyNavBar;
