import React from 'react';
import styled from 'styled-components';

import { defaultPadding, FilmsMasterPage } from './KarinaFilms';
import { formatTextForHtml } from '../../libs/libSupport';
import MasterPage from '../MasterPage';
import { HeaderFontSize, HeaderText } from '../KarinaCommon';

import app from '../../appData';
import StickyNavBar from '../../libs/StickyNavBar';
import { formatImageUrl } from '../../libs/ImageFormatter';

export enum FilmDataRecordType { text, pressQuotes, awards, press, castAndCrew, photoGallery, screenings, custom }
export interface FilmDataRecord {
    caption: string;
    recordType: FilmDataRecordType;
    data: string | ReviewRecord[] | AwardRecord[] | ScreeningYearRecord[] | string[];
    centerRectangle?: boolean;      // if true overrides staggered placement and centers this one
}

const AFilmByText = styled.p`
    margin-top: 0;
    margin-bottom: 32px;
`
// center overrides leftPct
const SectionContainer = styled.div<{ leftPct: number; center?: boolean }>`
    padding: 24px;
    width: 60%;
    text-align: left;
    margin-top: 32px;
    margin-left: ${props => props.center ? "auto" : props.leftPct + "%"};
    margin-right: ${props => props.center ? "auto" : undefined};
    background-color: ${app.themes.backShadeColor};
`
const CastAndCrewMember = styled.div`
    margin-top: 48px;
    p {
        margin-top: 12px;
        margin-bottom: 0;
    }
`
interface FilmPageProps {
    data: FilmDataRecord[];
    title: string;
    trailer: string;
    customComponent?: React.FC<{ caption: string }>;       // required if any record types are "custom"
}
const FilmPage: React.FC<FilmPageProps> = (props) => {
    const [stickyMenu, setStickyMenu] = React.useState<string[]>();

    React.useEffect(() => {
        window.scrollTo(0, 0);
        const menu: string[] = [];
        for (const item of props.data) {
            menu.push(item.caption);
        }
        setStickyMenu(menu);
    }, []);


    const marginStepPct = 10;
    const marginStepModulo = 4;
    console.log(props.data)
    return (
        <MasterPage>
            <FilmsMasterPage tightSpacing={true}>
                <HeaderText marginBottom={18} shadowColor={app.themes.goldColor} text={props.title} />
                <AFilmByText>A film by Karina Epperlein</AFilmByText>
                <Trailer vimeoId={props.trailer} />
                {stickyMenu &&
                    <StickyNavBar id="films-nav" buttonStyles={{ ...app.themes.buttonStyles, backgroundColor: app.themes.backShadeColor }} hoverStyles={app.themes.buttonHoverStyles}
                        menu={stickyMenu} darkBackColor={app.themes.stickyMenuBackColor} normalBackColor="transparent" />
                }
                {props.data.map((item, index) => {
                    if (item.recordType === FilmDataRecordType.text) {
                        return (
                            <SectionContainer key={item.caption} id={formatTextForHtml(item.caption)}
                                center={item.centerRectangle} leftPct={(index % marginStepModulo) * marginStepPct}>
                                <SectionHeaderText caption={item.caption} />
                                <div dangerouslySetInnerHTML={{ __html: item.data as string }} />
                            </SectionContainer>
                        )
                    } else if (item.recordType === FilmDataRecordType.awards) {
                        return (
                            <SectionContainer key={item.caption} id={formatTextForHtml(item.caption)}
                                center={item.centerRectangle} leftPct={(index % marginStepModulo) * marginStepPct}>
                                <SectionHeaderText caption={item.caption} shadowColor={app.themes.goldColor} />
                                <Awards key={item.caption} awards={item.data as AwardRecord[]} />
                            </SectionContainer>
                        )
                    } else if (item.recordType === FilmDataRecordType.castAndCrew) {
                        return (
                            <SectionContainer key={item.caption} id={formatTextForHtml(item.caption)}
                                center={item.centerRectangle} leftPct={(index % marginStepModulo) * marginStepPct}>
                                <SectionHeaderText caption={item.caption} />
                                {(item.data as string[]).map((item, cAndcIndex) => {
                                    return (
                                        <CastAndCrewMember key={cAndcIndex}>
                                            <div dangerouslySetInnerHTML={{ __html: item }} />
                                        </CastAndCrewMember>
                                    )
                                })}
                            </SectionContainer>
                        )
                    } else if (item.recordType === FilmDataRecordType.press) {
                        return (
                            <SectionContainer key={item.caption} id={formatTextForHtml(item.caption)}
                                center={item.centerRectangle} leftPct={(index % marginStepModulo) * marginStepPct}>
                                <SectionHeaderText caption={item.caption} />
                                <p>Click on a link below to read article as pdf file:</p>
                                <div dangerouslySetInnerHTML={{ __html: item.data as string }} />
                            </SectionContainer>
                        )
                    } else if (item.recordType === FilmDataRecordType.pressQuotes) {
                        return (
                            <SectionContainer key={item.caption} id={formatTextForHtml(item.caption)}
                                center={item.centerRectangle} leftPct={(index % marginStepModulo) * marginStepPct}>
                                <SectionHeaderText caption={item.caption} />
                                <PressQuotes key={item.caption} reviews={item.data as ReviewRecord[]} />
                            </SectionContainer>
                        )
                    } else if (item.recordType == FilmDataRecordType.photoGallery) {
                        return (
                            <SectionContainer key={item.caption} id={formatTextForHtml(item.caption)}
                                center={item.centerRectangle} leftPct={(index % marginStepModulo) * marginStepPct}>
                                <SectionHeaderText caption={item.caption} />
                                <FilmPhotoGallery key={item.caption} photos={item.data as string[]} />
                            </SectionContainer>
                        )
                    } else if (item.recordType === FilmDataRecordType.screenings) {
                        return (
                            <SectionContainer key={item.caption} id={formatTextForHtml(item.caption)}
                                center={item.centerRectangle} leftPct={(index % marginStepModulo) * marginStepPct}>
                                <SectionHeaderText caption={item.caption} />
                                <Screenings key={item.caption} years={item.data as ScreeningYearRecord[]} />
                            </SectionContainer>
                        )
                    } else if (item.recordType === FilmDataRecordType.custom) {
                        return (
                            <SectionContainer key={item.caption} id={formatTextForHtml(item.caption)}
                                center={item.centerRectangle} leftPct={(index % marginStepModulo) * marginStepPct}>
                                <SectionHeaderText caption={item.caption} />
                                {props.customComponent && (<props.customComponent {...{ caption: item.caption }} />)}
                            </SectionContainer>
                        )
                    }
                })}
            </FilmsMasterPage>
        </MasterPage >
    )
}
interface SectionHeaderTextProps {
    caption: string;
    shadowColor?: string;
}
const SectionHeaderText: React.FC<SectionHeaderTextProps> = (props) => {
    return (
        <HeaderText fontSize={HeaderFontSize.small} shadowColor={props.shadowColor} marginTop={0} marginBottom={24} text={props.caption} />
    )
}
//--------------------------------
const TrailerContainer = styled.div`
    margin-left: auto;
    margin-right: auto;
    iframe {
        width: 640px;
        height: 470px;
    }
    p {
        text-align: center;
        margin: 0;
    }
`
interface TrailerProps {
    vimeoId: string;
}
const Trailer: React.FC<TrailerProps> = (props) => {
    return (
        <TrailerContainer>
            <iframe style={{ border: "none" }}
                src={'https://player.vimeo.com/video/' + props.vimeoId + ' width="640" height="470" frameborder="0" allow="autoplay; fullscreen; picture-in-picture'} allowFullScreen>
            </iframe>
        </TrailerContainer>
    )
}
// <iframe src="https://player.vimeo.com/video/63475132?h=e74fe2cca9" width="640" height="470" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
// <p><a href="https://vimeo.com/63475132">Voices From Inside (1996) Trailer</a> from <a href="https://vimeo.com/karinafilms">Karinafilms</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
//------------------------------------------------------------
export interface AwardRecord {
    festival: string;       // including city
    award: string;          // including year
}

const AwardsMasterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
`
const AwardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 340px;
    margin-bottom: 16px;
    img {
        width: auto;
        height: 100%;
    }
`
const WreathImageContainer = styled.div`
    height: 82px;
`
const AwardText = styled.div`
    p {
        margin: 0 8px 0 8px;
        text-align: center;
    }
`
interface AwardsProps {
    awards: AwardRecord[];
}
const Awards: React.FC<AwardsProps> = (props) => {
    return (
        <React.Fragment>
            <a href="https://karinaland.com/graphics/films/press/academy.pdf" target="_blank" rel="noopener noreferrer">
                <p style={{ textAlign: "center" }}>2006 &ldquo;Short-listed&rdquo; for Oscar Nomination: Short Documentary</p>
            </a>
            <AwardsMasterContainer>
                {props.awards.map((award, index) => {
                    return (
                        <AwardContainer key={index}>
                            <WreathImageContainer>
                                <img src={formatImageUrl("wreath-left.png", { graphicsSubfolder: "films" })} />
                            </WreathImageContainer>
                            <AwardText>
                                <p style={{ fontSize: "16px", lineHeight: "20px" }}>{award.festival}</p>
                                <p style={{ color: app.themes.goldColor }} dangerouslySetInnerHTML={{ __html: award.award }} />
                            </AwardText>
                            <WreathImageContainer>
                                <img src={formatImageUrl("wreath-right.png", { graphicsSubfolder: "films" })} />
                            </WreathImageContainer>
                        </AwardContainer>
                    )
                })}
            </AwardsMasterContainer>
        </React.Fragment>
    )
}
//------------------------------------------------------------
export interface ReviewRecord {
    text: string;
    author: string;
    pub?: string;
}
const PubText = styled.span`
    color: ${app.themes.goldColor};
`
interface PressQuotesProps {
    reviews: ReviewRecord[];
}
const PressQuotes: React.FC<PressQuotesProps> = (props) => {
    return (
        <React.Fragment>
            {props.reviews.map(review => {
                return (
                    <p key={review.author}>
                        &ldquo;<span dangerouslySetInnerHTML={{ __html: review.text }} />&rdquo;
                        &nbsp;<PubText>&mdash;&nbsp;{review.author}</PubText>
                        {review.pub &&
                            <React.Fragment>
                                <span>,&nbsp;</span><PubText>{review.pub}</PubText>
                            </React.Fragment>
                        }
                    </p>
                )
            })}
        </React.Fragment>
    )
}
//------------------------------------------------------------
const PhotoGalleryContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
`
const ImageDiv = styled.div`
    width: 42%;
    margin: 8px;
`
const GalleryImage = styled.img`
    width: 100%;
    height: auto;
`
interface FilmPhotoGalleryProps {
    photos: string[];
}
const FilmPhotoGallery: React.FC<FilmPhotoGalleryProps> = (props) => {
    return (
        <PhotoGalleryContainer>
            {props.photos.map(filename => {
                return (
                    <ImageDiv>
                        <GalleryImage key={filename} src={formatImageUrl(filename, { graphicsSubfolder: "films" })} />
                    </ImageDiv>
                )
            })}
        </PhotoGalleryContainer>
    )
}
//------------------------------------------------------------
const GoldSpan = styled.span`
    color: ${app.themes.goldColor};
`
export interface ScreeningRecord {
    venue: string;
    award?: string;      // shown in gold with stars
}
export interface ScreeningYearRecord {
    year?: string;
    screenings: ScreeningRecord[];
}
interface ScreeningsProps {
    years: ScreeningYearRecord[];
}
const Screenings: React.FC<ScreeningsProps> = (props) => {
    return (
        <React.Fragment>
            {props.years.map(year => {
                return (
                    <React.Fragment key={year.year}>
                        {year.year && <h3>{year.year}</h3>}
                        {year.screenings.map((screening, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <GoldSpan>&#8226;</GoldSpan>&nbsp;
                                    <span dangerouslySetInnerHTML={{ __html: screening.venue }} />
                                    {screening.award &&
                                        <GoldSpan>{" ***" + screening.award + "***"}</GoldSpan>
                                    }
                                    <br />
                                </React.Fragment>
                            )
                        })}
                    </React.Fragment>
                )
            })}
        </React.Fragment>
    )
}

export default FilmPage;