import path from "path-browserify";
import { insert } from "./libSupport";

import { cdnDomain, ImageFileOptions, ImageRecord, VideoStreamSource } from "../interfaces/lib-api-interfaces";

import app from '../appData';
import { useFetchApi } from "./useDataApiV2";

// use this if image could be video or blob; (use formatImageUrl if filename only)
export const formatImageOrVideoSrcWithFile = (image: ImageRecord, fileOptions?: ImageFileOptions): string => {
    if (image.stream_thumb_src) {
        return image.stream_thumb_src;
    }
    return (image.file ? URL.createObjectURL(image.file) : formatImageUrl(image.filename!, fileOptions));
}

// if this is on nginx server we just need https://domain or  https://CDN-DOMAIN
// if this is on local dev server, domain depends on whether this is dashboard and whether image is on cdn
const formatDomainPart = (isCdn?: boolean) => {
    const removeTld = (domain: string): string => {
        return domain.substring(0, domain.indexOf('.'));
    }
    if (window.location.hostname !== "localhost") {
        // on remote server
        return "https://" + (isCdn ? cdnDomain : app.targetDomain);
    } else {
        if (app.isDashboard) {
            let result = "http://localhost:4000/";
            if (isCdn) {
                result += removeTld(cdnDomain) + "/cdn/";
            } else {
                result += removeTld(app.targetDomain) + "/public/";
            }
            return result;
        } else {
            return process.env.PUBLIC_URL + "/";
        }
    }
}
// if size is passed it is inserted with underscore before file extension
// e.g.: insert("hello.jpg", "f") -> "hello_f.jpg"
// dashboard uses targetDomain as root, otherwise use app.productGraphicsBaseUrl (PUBLIC folder if running locally)
// app.targetDomain = "laurainbocrystals.com" or "sam.bond" etc. (if this is dashboard the targetDomain is to the production site)
// if graphics are on cdn assume that they will be read off remote server even if this is dev server (app.allGraphicsLocal=true to override)
export const formatImageUrl = (filename: string, fileOptions?: ImageFileOptions) => {
    if (!filename) {
        return '';
    }
    // domainPart takes us through .com or .lol etc.
    const domainPart = formatDomainPart(fileOptions?.isCdn);
    const subFolder = "graphics/" + (fileOptions?.graphicsSubfolder ? (fileOptions.graphicsSubfolder) : '');
    const filenamePart = (fileOptions?.sizeDesignator && filename.length > 4) ? insert(filename, filename.length - 4, "_" + fileOptions.sizeDesignator) : filename;
    const url = domainPart + "/" + path.join(subFolder, filenamePart);
    //  console.log({ isDev, domainPart, subFolder, graphicsFolder, cdnTargetDomain, url });
    return url.replace("//graphics", "/graphics");
}
export const formatYoutubeThumbUrl = (id: string): string => {
    return "https://img.youtube.com/vi/" + id + "/1.jpg";
}
export const formatVimeoApiUrl = (id: string): string => {
    return "http://vimeo.com/api/v2/video/" + id + ".json";
}
export const formatVideoIframeSrc = (video: ImageRecord): string => {
    if (video.stream_source === VideoStreamSource.vimeo) {
        return "https://player.vimeo.com/video/" + video.stream_id;
    } else {
        return "https://www.youtube.com/embed/" + video.stream_id;
    }
}
export const fetchVimeoThumbSrc = (id: string, successCallback: (src: string) => void, errorCallback: (error: string) => void) => {
    try {
        const url = formatVimeoApiUrl(id);
        fetch(url).then(response => {
            if (!response.ok) {
                throw '';
            }
            response.json().then(json => successCallback(json[0].thumbnail_large));
        }).catch(() => { throw '' });
    } catch (err) {
        errorCallback("Vimeo fetch error");
    }
}
// remove domain info and sizing info; return filename itself
export const deformatImageUrl = (url: string, hasSizeDescriptor: boolean): string => {
    const nameStartPosn = url.lastIndexOf('/') + 1;
    let filename = url.substring(nameStartPosn);
    return filename.replace("_f.", '.').replace("_m.", '.');
}

// use following when loading html docs that include images or videos
// fileOptions is used for graphicsSubfolder and sizeDesignator; if not passed, subfolder omitted and no size inserted into filename
// domain id under cdn folder is taken from app.targetDomain (see formatImageUrl for all rules)
// if image is external and should not be formatted, attribute "isExternal" must be set to true
export const activateImagesAndVideos = (root: HTMLElement, fileOptions: ImageFileOptions, skipVideos: boolean) => {
    const imageElems = root.getElementsByTagName('img');
    for (let i = 0; i < imageElems.length; i++) {
        if (imageElems[i].getAttribute("isExternal") !== "true") {
            let name = imageElems[i].getAttribute("data-file");
            if (!name) {
                name = imageElems[i].getAttribute("src");
                if (name) {
                    name = formatImageUrl(deformatImageUrl(name, !!fileOptions.sizeDesignator));
                }
            }
            if (name) {
                imageElems[i].setAttribute("src", formatImageUrl(name, fileOptions));
            }
        }
    }
    if (!skipVideos) {
        const figures = root.getElementsByTagName('figure');
        for (let i = 0; i < figures.length; i++) {
            if (figures[i].getAttribute("data-stream-id")) {
                // this is a video; remove transparent blocking overlay
                const overlay = figures[i].getElementsByTagName('div');
                if (overlay.length && overlay[0].style.position === "absolute") {
                    figures[i].removeChild(overlay[0]);
                }
            }
        }
    }
}
/*
const iframe = document.createElement('iframe');
const src = formatVideoIframeSrc(image);
if (image.stream_source === VideoStreamSource.vimeo) {
    iframe.setAttribute("allow", "autoplay; fullscreen; picture-in-picture");
} else {
    iframe.setAttribute("allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share");
}
iframe.setAttribute("allowfullscreen", '');
iframe.setAttribute("src", src);
iframe.setAttribute("width", figSize + '');
iframe.setAttribute("height", Math.floor(figSize * videoHeightFactor) + '');
iframe.setAttribute("data-stream-id", image.stream_id);
iframe.setAttribute("data-stream-source", image.stream_source + '');
fig.appendChild(iframe);
*/