import React from 'react';
import styled from 'styled-components';
import { formatImageUrl } from '../libs/ImageFormatter';

import CloseDialogBar from '../libs/CloseDialogBar';

import app from '../appData';
import { HeaderText } from './KarinaCommon';

const largeDotSize = 24;
const dotSize = 18;
const dotHoverColor = app.themes.goldColor;
const dotSelectedColor = app.themes.goldColor;
const dotColor = app.themes.reverseForeColor;

const MasterContainer = styled.div<{ foreColor: string }>`
    width: 100%;
    color: ${props => props.foreColor};
    padding-top: 20px;
    margin-bottom: 28px;
`
const TimeLine = styled.div`
    position: relative;
`
const SolidLine = styled.div<{ foreColor: string }>`
    margin-top: 8px;
    width: 100%;
    height: 2px;
    background-color:${props => props.foreColor};
`
const Overlay = styled.div`
    width: 100%;
    z-index: 1000;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    transform: translatey(-9px);
`
const TimeBlock = styled.div<{ isSelected: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    div:hover {
        background-color: ${props => props.isSelected ? dotSelectedColor : dotHoverColor};
        height: ${largeDotSize}px;
        width: ${largeDotSize}px;
    }
`
const Dot = styled.div<{ isSelected: boolean }>`
    height: ${props => props.isSelected ? largeDotSize : dotSize}px;
    width: ${props => props.isSelected ? largeDotSize : dotSize}px;
    background-color: ${props => props.isSelected ? dotSelectedColor : dotColor};
    border-radius: 50%;
`
interface TimelineRecord {
    dates: string;
    popupImage: string;
    url: string;
}
const dates: TimelineRecord[] = [
    { dates: "1953-71", popupImage: "gold-postcard.jpg", url: '' },
    { dates: "1972-81", popupImage: "phoenix-card-redo.jpg", url: '' },
    { dates: "1982-91", popupImage: "voices-from-inside-postcard.jpg", url: '' },
    { dates: "1992-99", popupImage: "not-sad-postcard.jpg", url: '' },
    { dates: "2000-10", popupImage: "awakening-postcard-copy.jpg", url: '' },
    { dates: "2011-18", popupImage: '', url: '' },
    { dates: "2019-", popupImage: '', url: '' },
];
interface PopupImageRecord {
    index: number;
    left: number;
    top: number;
}
interface LifeAndWorkProps {
}
const LifeAndWork: React.FC<LifeAndWorkProps> = (props) => {
    const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);
    const [popup, setPopup] = React.useState<PopupImageRecord>();

    const timelineHover = (index: number) => {
        const coverDot = index < 3;
        const elem = document.getElementById(dates[index].dates);
        if (elem) {
            const timelineRect = document.getElementById("timeline")!.getBoundingClientRect();
            const rect = elem.getBoundingClientRect();
            setPopup({ index, left: rect.x - timelineRect.width * .9 / dates.length / 2, top: rect.y + (coverDot ? -125 : 30) });
        }
    }
    const dotClicked = (index: number) => {
        setPopup(undefined);
        setSelectedIndex(index);
    }
    const closePopup = (index: number) => {
        if (index >= 3) {
            setPopup(undefined);
        }
    }

    return (
        <MasterContainer foreColor={app.themes.reverseForeColor}>
            <HeaderText shadowColor={app.themes.goldColor} text="life and work" />
            <TimeLine id="timeline">
                <SolidLine foreColor={app.themes.reverseForeColor} />
                <Overlay>
                    {dates.map((record, index) => {
                        return (
                            <TimeBlock key={record.dates} isSelected={selectedIndex === index}>
                                <Dot id={record.dates} isSelected={selectedIndex === index}
                                    onClick={() => dotClicked(index)} onMouseOver={() => timelineHover(index)} onMouseLeave={() => closePopup(index)} />
                                <p>{record.dates}</p>
                            </TimeBlock>
                        )
                    })}
                </Overlay>
            </TimeLine>
            {popup &&
                <PopupImage top={popup.top} left={popup.left} src={formatImageUrl(dates[popup.index].popupImage, { graphicsSubfolder: "films" })}
                    onMouseLeave={() => closePopup(6)} onClick={() => dotClicked(popup.index)} />
            }
            {selectedIndex >= 0 &&
                <FakePeriodBio onClose={() => setSelectedIndex(-1)} />}
        </MasterContainer>
    )
}
const PopupImage = styled.img<{ top: number; left: number }>`
    width: ${90 / dates.length}%;
    height: auto;
    top: ${props => props.top}px;
    left: ${props => props.left}px;
    position: fixed;
    z-index: 1100;
`
interface ShowPopupImageProps {
    popup: PopupImageRecord;
}
const ShowPopupImage: React.FC<ShowPopupImageProps> = (props) => {

    return (
        <PopupImage top={props.popup.top} left={props.popup.left} src={formatImageUrl(dates[props.popup.index].popupImage, { graphicsSubfolder: "films" })} />
    )
}

const fakeBioText = `
<p><span style="font-weight: bold;">1992 to 2001:</span>&nbsp;Between 1992 and 1996 Karina taught creative expression classes for teens and adults in drug rehab facilities in Oakland and East Palo Alto. 
She produced and directed <span style="font-style: italic;">Voices from Inside</span> (1996, 60 min), a documentary about women in prison and their children on the outside. 
It was the culmination of her four years of teaching as a volunteer at a federal women's prison. Karina traveled extensively with the film, 
leading discussions and running workshops at festivals, universities, conferences and community groups. 
Voices won the National Council on Crime and Delinquency’s PASS Media Award (Prevention for a Safer Society).</p>
<p>From four years of close friendship with a 94-year-old Armenian woman who survived the genocide of her people in 1915, Karina created 
<span style="font-style: italic;">I Will Not Be Sad In This World</span> (2001, 56 min). She spent much time in Zaroohe’s home in Fresno with her digital video camera. 
This lyrical portrait – as most of Karina’s other works – uses fairytale, poetry and art to take the viewer on an inner journey. 
The film has been shown at numerous festivals, and is used in high schools and universities.</p>
`
const BioContainer = styled.div<{ backColor: string }>`
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: ${props => props.backColor};
    color: black;
    p {
        margin-left: 16px;
        margin-right: 16px;
    }
`
const ImageDiv = styled.div`
    min-width: 20%;
`
const TextDiv = styled.div`
    position: relative;
    div {
        margin-top: 36px;
    }
`
const Image = styled.img`
    width: 100%;
    height: auto;
    margin-left: 8px;
    margin-top: 36px;
`
const ImageCaption = styled.p`
    margin: 0;
    text-align: center;
    font-style: italic;
    font-size: 13px;
`
interface FakePeriodBioProps {
    onClose: () => void;
}
const FakePeriodBio: React.FC<FakePeriodBioProps> = (props) => {
    return (
        <BioContainer backColor={app.themes.backColor10}>
            <ImageDiv>
                <Image src={formatImageUrl("sad.jpg", { graphicsSubfolder: "timeline" })} />
                <ImageCaption>Genocide survivor Zaroohe</ImageCaption>
            </ImageDiv>
            <TextDiv>
                <CloseDialogBar marginRight={8} onClose={props.onClose} />
                <div dangerouslySetInnerHTML={{ __html: fakeBioText }} />
            </TextDiv>
        </BioContainer>
    )
}
export default LifeAndWork;
//                 <Image src={formatImageUrl("sad.jpg", { graphicsSubfolder: "timeline" })} />
