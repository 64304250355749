import React from 'react';
import styled from 'styled-components';

import LifeAndWork from './LifeAndWork';
import MasterPage from './MasterPage';
import Slider from '../libs/SliderV3';
import { SliderRecord } from '../interfaces/lib-react-interfaces';

import app from '../appData';
import { formatImageUrl } from '../libs/ImageFormatter';
import Button from './KarinaCommon';

const carouselImages: SliderRecord[] = [
    { src: '/graphics/carousel/voices-notsad-updated.jpg' },
    { src: '/graphics/carousel/gold-banner-updated.jpg' },
    { src: '/graphics/carousel/tai-chi-trebuchet-banner.jpg' },
    { src: '/graphics/carousel/k_phoenix-updated.jpg' },
    { src: '/graphics/carousel/gold-title-banner.jpg' }
];

const introText = `To begin, I want to give big thanks first of all to Bob Ng, my cello teacher par excellence. He is among other things also a life long art photographer. 
His beautiful black and white photos of the 641 Garage BLM Memorial Mural inspire me. Now is the time to try a new adventure and follow Bob’s suggestion to write a blog. 
Among other things an attempt to tell the unfolding story of this ongoing art project. A mural started on private property in public view, becoming collaboration, public art, 
dialogue, education, and expression of my life’s passions. Everything I’ve experienced in my life, have learned or undertaken has been flowing into this project. 
I am especially looking forward to introduce you to my wonderful collaborators. And I invite comments from you: my friends, contributors, neighbors, on-lookers, dog walkers, 
by-passers on foot, bike, regular or occasional. Or in cars to Tilden Park occasionally stopping to take a still and send it to Thailand or Italy.`

const portraitCaption = "Karina Epperlein";
const portraitCredit = "photo by John Knoop";
const portraitUrl = "portrait.jpg";
const titleImageUrl = "karinaland-blog.png";
const introHeader = "here is karina";

const IntroBoxContainer = styled.div`
    display: flex;    
    width: 100%;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    background-color: ${app.themes.backShadeColor};
`
const PortraitImageWithCaptionAndCredit = styled.div`
    padding: 16px;
    padding-left: 32px;
    width: 25%;
    flex-shrink: 0;
`
const PortraitImageBox = styled.div`
    position: relative;
`
const PhotoCredit = styled.span`
    font-size: 12px;
    transform: rotate(-90deg);
    position: absolute;
    bottom: 80px;
    left: -70px;
`
const PortraitImagePlusCaption = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const CaptionText = styled.p`
    text-align: center;
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    margin: 0;
    margin-top: 8px;
`
const PortraitImage = styled.img`
    width: 100%;
    height: auto;
`
const TextAndHeaderAndButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const TitleImage = styled.img`
    margin: 16px auto 0 auto;
    height: 95px;
    width: auto;
`
const IntroHeaderText = styled.p`
    font-weight: bold;
    margin-top: 0;
`
const IntroText = styled.p`
    margin: 0 40px 8px 48px;
`
const Home: React.FC = () => {
    return (
        <MasterPage>
            <Slider images={carouselImages} delay={5000} alignLeft={false} />
            <LifeAndWork />
            <IntroBoxContainer>
                <PortraitImageWithCaptionAndCredit>
                    <PortraitImageBox>
                        <PhotoCredit>{portraitCredit}</PhotoCredit>
                        <PortraitImagePlusCaption>
                            <PortraitImage src={formatImageUrl(portraitUrl)} />
                            <CaptionText>{portraitCaption}</CaptionText>
                        </PortraitImagePlusCaption>
                    </PortraitImageBox>
                </PortraitImageWithCaptionAndCredit>
                <TextAndHeaderAndButton>
                    <TitleImage src={formatImageUrl(titleImageUrl)} alt={app.targetDomain} />
                    <IntroHeaderText>{introHeader}</IntroHeaderText>
                    <IntroText>{introText}</IntroText>
                    <Button fontSize={20} marginTop={20} darkBackground={true} caption="View blog" onClick={() => window.open(app.blogUrl, '_blank', 'noopener') } />
                </TextAndHeaderAndButton>
            </IntroBoxContainer>
        </MasterPage>
    );
}


export default Home;
