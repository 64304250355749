import React from 'react';
import styled from 'styled-components';
import scrollIntoView from 'scroll-into-view';

import { formatImageUrl } from '../libs/ImageFormatter';
import MasterPage from './MasterPage';
import { HeaderText } from './KarinaCommon';
import StickyNavBar from '../libs/StickyNavBar';
import { defaultPadding } from './films/KarinaFilms';

import app from '../appData';

const graphicsSubfolder = "about";
const photo = "k-door-200x300.jpg";

interface PlacementRecord {
    location: { top: number; left: number };    // top is px, left is %
    width: number;      // %
    filename: string;       // png file
    id: string;
}
const graphics: PlacementRecord[] = [
    {
        location: { top: 80, left: 10 },
        width: 28,
        filename: "swirl_theater.png",
        id: "theater"
    },
    {
        location: { top: -30, left: 26 },
        width: 44,
        filename: "swirl_documentary.png",
        id: "documentary"
    },
    {
        location: { top: 0, left: 8 },
        width: 46,
        filename: "swirl_teaching.png",
        id: "teaching"
    },
    {
        location: { top: -40, left: 56 },
        width: 42,
        filename: "swirl_karinaland.png",
        id: "karinaland"
    }
];

const bioText = `<p>Independent filmmaker Karina Epperlein has 36 years of experience as a theater artist, teacher and filmmaker. A native of Germany, 
she came to the United States in 1981 as a dancer, choreographer and actress with the avant-garde theater company SOON 3. 
Karina developed her own poetic vision of theater directing, writing and performing original works. Haunted by her country’s past, 
she created the one-woman show i.e. Deutschland (1988 - 93) dealing with the aftermath of the Holocaust. Karina kept rewriting the piece 
as new historical events unfolded (the fall of the Wall, reunification), and it was widely performed at theaters and universities.</p>`


/*
<p><img style="float: left; margin-right: 16px; width: 20%; height: auto;" src=${photo} />Independent filmmaker Karina Epperlein is a native of Germany and has worked in 
Europe and the U.S. for the past thirty-eight years as a filmmaker, theater artist, and teacher. 
Karina&apos;s work is always looking into dark corners, finding the light, 
addressing the themes of justice, transformation, and healing. Finding the Gold Within is her tenth film project as director/producer.</p>

<p>Her film Phoenix Dance (2006, 16 min & 23 min) &mdash; recounting dancer Homer Avila&apos;s battle with cancer and his remarkable return to the stage as a one-legged dancer 
in a pas de deux by Alonzo King &mdash; has been screened in more than 100 festivals and theaters all over the world. It was &quot;short-listed&quot; for the 2006 Oscar Nomination for 
Short Documentary and has won twelve awards, including a Golden Gate Award from the San Francisco Int&apos;l Film Festival. This work was also photographed and edited by Karina.</p>

<p>Her earlier video pieces Labyrinthian (1984, 20 min) and i.e. Deutschland (1988, 8 min) were followed by Voices from Inside (1996, 60 min), a documentary about 
women in prison and their children on the outside. It was the culmination of her four years of teaching theater as a volunteer at a federal women&apos;s prison. 
Karina traveled extensively with the film, leading discussions at festivals and running workshops at universities, conferences, and community groups. 
Voices from Inside won the National Council on Crime and Delinquency&apos;s PASS Media Award (Prevention for a Safer Society), and is part of New Day Films&apos; collection.</p>

<p>From four years of close friendship with a 94-year-old Armenian woman who survived the genocide of her people in 1915, Karina created 
I Will Not Be Sad In This World (2001, 56 min). She spent countless hours in Zaroohe&apos;s home in Fresno with her digital video camera. 
This lyrical portrait &mdash; like most of Karina&apos;s other work &mdash; uses fairytale, poetry, and art to take the viewer on an inner journey. 
The film has been shown at numerous festivals, and is used in high schools and universities. Karina directed, co-produced, and photographed 
We Are Here Together (2003, 65 min), a film about the tempestuous first year of an alternative charter high school in Alameda, California. 
She also directed and photographed Women&apos;s Rites (2000, 37 min) about five women from Europe and the U.S. studying the Anna Halprin Life/Art Process, 
and In the Shadow of Huma&apos;s Wing (2008, 56 min), a highly intimate, experiential film about somatic psychotherapy.</p>

<p>She co-produced, co-directed and co-edited with filmmaker John Knoop the documentary Awakening from Sorrow: Buenos Aires 1997 (2009, 40 min.) The film, which 
contemplates the history of Argentina&apos;s &quot;Dirty War&quot; and gives voice to the children of the disappeared and their emerging HIJOS movement, screened at festivals 
here and abroad and is used in the classroom.</p>
*/

const MasterContainer = styled.div`
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
`
const BioImageAndText = styled.div`
    display: flex;
    align-items: center;    
`
const BioImage = styled.img`
    float: left;
    margin-right: 16px;
`
const SectionStub = styled.div<{ marginLeftPct: number }>`
    height: 500px;
    width: 70%;
    margin-left: ${props => props.marginLeftPct}%;
    border: 1px solid white;
    margin-top: 50px;
`
const About: React.FC = () => {
    console.log("pathname=" + window.location.pathname)
    const [stickyMenu, setStickyMenu] = React.useState<string[]>();

    React.useEffect(() => {
        const menu: string[] = [];
        for (const item of graphics) {
            menu.push(item.id);
        }
        setStickyMenu(menu);
    }, []);

    const swirlClicked = (id: string) => {
        const elem = document.getElementById(id);
        if (elem) {
            scrollIntoView(elem, { time: 1000, align: { top: 0, topOffset: 100, lockX: true } });
        }
    }

    return (
        <MasterPage>
            <MasterContainer>
                <HeaderText text="biography" />
                <BioImageAndText>
                <BioImage src={formatImageUrl(photo, { graphicsSubfolder })} />
                <div dangerouslySetInnerHTML={{ __html: bioText }} />
                </BioImageAndText>
                <Swirls swirlClicked={swirlClicked} />
                {stickyMenu &&
                    <StickyNavBar id="about-nav" maxWidth={800}
                        menu={stickyMenu} darkBackColor={app.themes.stickyMenuBackColor} />
                }
                <SectionStub id="theater" marginLeftPct={0}>
                    <HeaderText text='here is the "theater section"' />
                </SectionStub>
                <SectionStub id="documentary" marginLeftPct={10}>
                    <HeaderText text='here is the "documentary cinema section"' />
                </SectionStub>
                <SectionStub id="teaching" marginLeftPct={20}>
                    <HeaderText text='here is the "teaching section"' />
                </SectionStub>
                <SectionStub id="karinaland" marginLeftPct={30}>
                    <HeaderText text='Here is the "karinaland section"' />
                </SectionStub>
            </MasterContainer>
        </MasterPage>
    )
}
//----------------------------------------------------------
const graphicFactor = 0.65;
const SwirlMasterContainer = styled.div`
    display: flex;
    margin-left: 20%;
`
const VSwirlContainer = styled.div`
    width: 15%;
`
const VSwirlImage = styled.img`
    width: ${graphicFactor * 100}%;
    height: auto;
`
const SwirlContainer = styled.div`
    width: 85%;
`
const SwirlImage = styled.img<{ left: number; top: number; width: number }>`
    margin-top: ${props => props.top}px;
    margin-left: ${props => props.left}%;
    width: ${props => props.width}%;
    height: auto;
    display: block;
    cursor: pointer;
`
interface SwirlsProps {
    swirlClicked: (id: string) => void;
}
const Swirls: React.FC<SwirlsProps> = (props) => {
    const imageEnterOrLeave = (filename: string, isEntering: boolean) => {
        const elem = document.getElementById(filename) as HTMLImageElement;
        if (isEntering) {
            filename = filename.replace(".png", "-gold.png");
        }
        elem.src = formatImageUrl(filename, { graphicsSubfolder });
    }

    return (
        <SwirlMasterContainer>
            <VSwirlContainer>
                <VSwirlImage src={formatImageUrl("about-v-swirl.png", { graphicsSubfolder })} />
            </VSwirlContainer>
            <SwirlContainer>
                {graphics.map(record => {
                    return (
                        <SwirlImage id={record.filename} left={record.location.left * graphicFactor} top={record.location.top * graphicFactor} width={record.width * graphicFactor}
                            src={formatImageUrl(record.filename, { graphicsSubfolder })} onClick={() => props.swirlClicked(record.id)}
                            onMouseEnter={() => imageEnterOrLeave(record.filename, true)} onMouseLeave={() => imageEnterOrLeave(record.filename, false)} />
                    )
                })}
            </SwirlContainer>
        </SwirlMasterContainer>
    )
}
export default About;

