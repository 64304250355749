const shoppingCart = false;
const isDashboard = false;
export const loginAllowed = false;
export const analytics = false;

const targetDomain = "karinaland.com";

export const adminCustId = 1;
export const productGraphicsBaseUrl = (window.location.hostname === "localhost" ? process.env.PUBLIC_URL : ("https://" + targetDomain)) + "/graphics";

const blogUrl = "https://karinalandriver.blogspot.com/";

// themes is a standard object for passing to custom components
/* typical uses:
    backColor10 - input fields, forecolor when background is reversed
    backColor25 - buttons, logo, dropdown menus
    backColor50 - captions on grids (e.g., store near you)
*/
const backShadeColor = "rgba(0, 0, 0, 0.4)";
export const themes = {
    defaultFonts: "'Nunito Sans', sans-serif",
    navbarFont: "'Nunito Sans', sans-serif",
    sansFonts: "'Nunito Sans', sans-serif",
    h1FontFamily: "'Nunito Sans', sans-serif",
    foreColor: "black",
    reverseForeColor: "#d2d4d6",
  //  backColor100: "#1a2e46",
    backColor100: "#213349",
    backColor50: "#0f171e",
    backColor25: "#a9abae",
    backColor10: "#d2d4d6",
    goldColor: "#a0852c",
    lightGoldColor: "#c6aa69",
    backShadeColor,
    stickyMenuBackColor: "#0b1628",
    textShadowRed: "#fb3148",
    buttonStyles: {
        backgroundColor: backShadeColor,
        border: "2px solid maroon",
        fontSize: "17px",
        fontWeight: "bold",
        color: "maroon",
        padding: "8px"
    } as Record<string, string>,
    buttonHoverStyles: {
        backgroundColor: "rgba(160, 133, 44, 0.4)",
        color: "red"
    }
};

// categories are fetched from master page and persisted into local storage
export const facebookUrl = "https://www.facebook.com/Heart-the-Moment-501440043350302/";
export const banner = "PROUDLY MADE IN THE U.S.A";
//export const logoUrl = "other/logo_courier_black_withbg.jpg";
export const logoUrl = "logo_courier_black_withbg.jpg";

export const altLogo = "Heart the Moment";

export default  {
    shoppingCart,
    isDashboard,
    targetDomain,
    blogUrl,
    loginAllowed,
    analytics,
    adminCustId,
    productGraphicsBaseUrl,
    themes,
    facebookUrl,
    banner,
    logoUrl,
    altLogo,
}